import React, { useEffect, useState } from "react";
import AppLayout from "../Layout/AppLayout";
  import { NavLink, useParams,useNavigate } from "react-router-dom";
import departmentImg from "../../assets/images/Icons/SVG/Group 22974.svg";
import { useFormik } from "formik";
import API from "../../Api/Api";
import { useDispatch, useSelector } from "react-redux";
import { setObjectiveData } from "../../Redux/Slice";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
export default function DepartmentDetails() {
  const [departmentData, setDepartmentDataList] = useState(null);
  const postData = useSelector((state) => state.date.departmentData);
  const dispatch = useDispatch();
  const navigate=useNavigate();

  const validate = (values) => {
    console.log(values, "value");
    const errors = {};
    const spaceReg = /^\S.*\S$/;
    const paragraphReg=/^[^\s]+(\s+[^\s]+)*$/

    if (!values.dept_objective_name) {
      errors.dept_objective_name = "Please enter name";
    } else if (!spaceReg.test(values.dept_objective_name)) {
      errors.dept_objective_name = "Cannot start & end with a space";
    }
    if (!values.dept_objective_description) {
      errors.dept_objective_description = "Please enter description";
    } else if (!paragraphReg.test(values.dept_objective_description)) {
      errors.dept_objective_description = "Cannot start & end with a space";
    }
    if (!values.objective_id) {
      errors.objective_id = "Please select objective";
    }

    console.log("Erroes", errors);
    return errors;
  };
  const formik = useFormik({
    initialValues: {
      dept_objective_name: "",
      dept_objective_description: "",
      objective_id: "",
    },
    onSubmit: (values) => {
      console.log(values, "hello values");
      createDeptObjective();
    },
    validate,
  });

  const { id } = useParams();
  const { type } = useParams();
  const getSingledepartmentById = () => {
    if (id) {
      API.Department.getDepartmentObjectiveList(id)
        .then((response) => {
          if (response.data.statusCode === 200) {
            console.log(response, "setDepartmentDataByID->");
            setDepartmentDataList(response.data.data);
          }
        })
        .catch((err) => {
          if(err.response.status===403){
            navigate("/");
            }
          console.log(err);
        });
    }
  };

  useEffect(() => {
    getSingledepartmentById();
  }, [id]);
  // Create Department
  const createDeptObjective = () => {
    API.Department.createDepartment({
      data: {
        objective_id: formik.values.objective_id,
        dept_objective_name: formik.values.dept_objective_name,
        dept_objective_description: formik.values.dept_objective_description,
      },
    })
      .then((response) => {
        if (response.data.statusCode === 200) {
          console.log(response);
          formik.resetForm();

          // Check if the element exists before trying to click it
          document
            .querySelector(`#AddDepartment${formik.values.objective_id}`)
            .click();
          //  document.querySelector(".btn-close").click();

          getSingledepartmentById();
        } else {
          toast.error(response?.data?.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleButtonClick = (objectiveId) => {
    console.log("cliked");
    console.log(objectiveId, "objectiveId");
    formik.setFieldValue("objective_id", objectiveId);
  };

  const NeededInfo = (objName, KeyResult, description, index) => {
    const newdata = {
      objName: objName,
      KeyResult: KeyResult,
      description: description,
      // percentage: percentage,
      index: index,
    };
    console.log(newdata, "newdatanewdatanewdata");
    dispatch(setObjectiveData(newdata));
  };
  return (
    <AppLayout>
      <div className="main-content mt-3">
        <div className="page-content mb-5">
          <div className="row welcomeHeading">
            <div className="col-12 d-sm-flex justify-content-between">
              <div className="d-flex align-items-center ps-3 mb-3 mb-sm-0">
                <NavLink className="" to="/department">
                  <div className="backArrow me-2">
                    <i className="fa fa-solid fa-chevron-left textDark"></i>
                  </div>
                </NavLink>
                <h3 className="mb-0 fw-bold">{postData?.DeptName}</h3>
              </div>
              <div className="d-flex align-items-center me-2">
                <img
                  src={
                    postData?.DeptImage
                      ? `${process.env.REACT_APP_BASE_URL}/images/${postData?.DeptImage}`
                      : departmentImg
                  }
                  className="me-3  Organization-img"
                />{" "}
                <div>
                  <small className="lightGrey mb-0">
                    {" "}
                    {postData?.DeptName} Owner
                  </small>
                  <h5 className="textDarkBlack mb-1 fw-bold">
                    {postData.fullname ? postData.fullname : "-"}
                  </h5>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-xl-12">
              <h3 className="textDarkBlack mb-1 fw-bold">
                Organization Objective
              </h3>
              <small className="textDarkGrey">
                Below are the list of your organizational objectives that this
                department is working towards
              </small>
              <div
                className="accordion accordion-flush mt-2"
                id="accordionFlushExample1"
              >
                {departmentData &&
                  departmentData?.map((item, index) => {
                    return (
                      <div className="accordion-item mb-3 border-0" key={index}>
                        <h2
                          className="accordion-header"
                          id={`flush-heading${index + 1}`}
                        >
                          <button
                            className="accordion-button progressCard p-3 collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={`#flush-collapse${index + 1}`}
                            aria-expanded="false"
                            aria-controls={`flush-collapse${index + 1}`}
                          >
                            <div className="row w-100 d-flex align-items-center">
                              <div className="col-lg-6 mb-3">
                                <h4 className="textBlueLight">
                                  #.{item?.objective_name}
                                </h4>
                                <p className="textDarkBlack mb-0">
                                  {item?.description}
                                </p>
                              </div>
                              <div className="col-lg-6">
                                <div className="row align-items-center">
                                  <div className="col-lg-1 mt-2">
                                    <h3 className="textBlueLight fw-bold">
                                      {item?.org_percentage_calculation
                                        ? Math.round(
                                            item?.org_percentage_calculation *
                                              100
                                          ) / 100
                                        : 0}
                                      %
                                    </h3>
                                  </div>
                                  <div className="col-xl-10 col-lg-11 ps-5">
                                    <div className="progress">
                                      <div
                                        className={`progress-bar`}
                                        role="progressbar"
                                        style={{
                                          width: `${item?.org_percentage_calculation}%`,
                                        }}
                                        aria-valuenow={
                                          item?.org_percentage_calculation
                                        }
                                        aria-valuemin="0"
                                        aria-valuemax="100"
                                      ></div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </button>
                        </h2>
                        <div
                          id={`flush-collapse${index + 1}`}
                          className="accordion-collapse collapse"
                          aria-labelledby={`flush-heading${index + 1}`}
                          data-bs-parent="#accordionFlushExample1"
                          // key={index}
                        >
                          <div className="accordion-body px-0">
                            {/* {item?.objectiveList?.length > 0 ? ( */}
                            <div className="position-relative">
                              {item?.objectiveList.map(
                                (objective, objIndex) => (
                                  <>
                                    <NavLink
                                      className="text-decoration-none"
                                      key={objIndex}
                                      to={`/timely-delivery/${objective?._id}/${type}`}
                                      onClick={() =>
                                        NeededInfo(
                                          item?.objective_name,
                                          objective?.dept_objective_name,
                                          objective?.dept_objective_description,
                                          // objective?.percentage_calculation,
                                          index
                                        )
                                      }
                                    >
                                      <div className="row w-100 d-flex align-items-center justify-content-cente mb-2">
                                        <div className="col-lg-1 d-flex justify-content-center">
                                          <div className="organizationNo">
                                            {objIndex + 1}
                                          </div>
                                          <div className="vLine"></div>
                                        </div>
                                        <div className="col-lg-11 p-3 bg-light-blue ">
                                          <div className="row align-items-center text-lg-start text-center">
                                            <div className="col-lg-10 mb-3">
                                              <h4 className="textBlueLight">
                                                {objective?.dept_objective_name}
                                              </h4>
                                              <p className="textDarkBlack mb-0">
                                                {
                                                  objective?.dept_objective_description
                                                }
                                              </p>
                                            </div>
                                            <div className="col-lg-2 d-flex justify-content-around align-items-center">
                                              <h3 className="textBlueLight fw-bold mb-0">
                                                {Math.round(
                                                  objective?.percentage_calculation *
                                                    100
                                                ) / 100}
                                                %
                                              </h3>
                                              <i className="fa fa-solid fa-chevron-right textBlueLight"></i>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </NavLink>
                                  </>
                                )
                              )}
                              {type === "user" ? (
                                <div className="row w-100 d-flex align-items-center justify-content-center mt-3">
                                  <div className="col-lg-1 d-flex justify-content-center">
                                    <div className="organizationNo addPlus">
                                      +
                                    </div>
                                    <div className="vLine"></div>
                                  </div>
                                  <div className="col-lg-11 p-3 ps-0 ">
                                    <button
                                      type="button"
                                      className="btn sign-up-button-color text-white px-5"
                                      data-bs-toggle="modal"
                                      data-bs-target={`#staticBackdrop${item._id}`}
                                      id={`AddDepartment${item._id}`}
                                      onClick={() =>
                                        handleButtonClick(item._id)
                                      }
                                    >
                                      Add Department Objective
                                    </button>
                                    <div
                                      className={`modal fade`} // Add 'show' class when modal is open
                                      id={`staticBackdrop${item._id}`}
                                      data-bs-backdrop="static"
                                      data-bs-keyboard="false"
                                      tabIndex={-1}
                                      aria-labelledby={`staticBackdropLabel${item._id}`}
                                      aria-hidden="true" // Hide modal when state is false
                                      style={{ zIndex: "99999" }}
                                    >
                                      <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable bg-transparent">
                                        <div className="modal-content">
                                          <div className="modal-header border-0">
                                            <button
                                              type="button"
                                              className="btn-close"
                                              data-bs-dismiss="modal"
                                              aria-label="Close"
                                              onClick={() => formik.resetForm()}
                                            />
                                          </div>
                                          <div className="modal-body px-5">
                                            <h3 className="textDarkBlack mb-4">
                                              Add Objective
                                            </h3>
                                            <p className="textSecondary">
                                              Ensure that the objective aligns
                                              with your organization&apos;s
                                              long-term goals and strategy. This
                                              will make it less likely to be
                                              changed in the future
                                            </p>

                                            <form>
                                              {/* <div className="mb-4">
                                                <div className="form-group">
                                                  <select
                                                    className="form-select form-control"
                                                    aria-label="Default select example"
                                                    id="definedepartment"
                                                    name="objective_id"
                                                    onChange={
                                                      formik.handleChange
                                                    }
                                                    value={
                                                      formik.values.objective_id
                                                    }
                                                  >
                                                    <option selected>
                                                      Select organizational
                                                      objective
                                                    </option>
                                                    {objectiveList &&
                                                      objectiveList.map(
                                                        (item) => {
                                                          return (
                                                            <option
                                                              key={item?._id}
                                                              value={item?._id}
                                                            >
                                                              {
                                                                item?.objective_name
                                                              }
                                                            </option>
                                                          );
                                                        }
                                                      )}
                                                  </select>
                                                </div>
                                                {formik.errors.objective_id &&
                                                formik.touched.objective_id ? (
                                                  <div className="text-danger">
                                                    {formik.errors.objective_id}
                                                  </div>
                                                ) : null}
                                              </div> */}
                                              <div className="mb-4">
                                                <div className="form-group">
                                                  <input
                                                    type="text"
                                                    name="dept_objective_name"
                                                    value={
                                                      formik.values
                                                        .dept_objective_name
                                                    }
                                                    onChange={
                                                      formik.handleChange
                                                    }
                                                    // maxLength="30"
                                                    className="form-control mt-2"
                                                    placeholder="Enter department objective"
                                                    id="exampleInputfirstname"
                                                  />
                                                </div>
                                                {formik.errors
                                                  .dept_objective_name &&
                                                formik.touched
                                                  .dept_objective_name ? (
                                                  <div className="text-danger">
                                                    {
                                                      formik.errors
                                                        .dept_objective_name
                                                    }
                                                  </div>
                                                ) : null}
                                              </div>
                                              <div className="mb-4">
                                                <div className="form-group">
                                                  <textarea
                                                    className="form-control"
                                                    name="dept_objective_description"
                                                    value={
                                                      formik.values
                                                        .dept_objective_description
                                                    }
                                                    onChange={
                                                      formik.handleChange
                                                    }
                                                    placeholder="Enter a small description of the objective..."
                                                    id="floatingTextarea"
                                                    rows="4"
                                                    // maxLength="100"
                                                  ></textarea>
                                                </div>
                                                {formik.errors
                                                  .dept_objective_description &&
                                                formik.touched
                                                  .dept_objective_description ? (
                                                  <div className="text-danger">
                                                    {
                                                      formik.errors
                                                        .dept_objective_description
                                                    }
                                                  </div>
                                                ) : null}
                                              
                                              </div>

                                              <div className="">
                                                <button
                                                  type="submit"
                                                  className="btn font-size-14  sign-up-button-color text-white px-5"
                                                  onClick={formik.handleSubmit}
                                                >
                                                  Publish
                                                </button>
                                              </div>
                                            </form>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                            </div>
                            {/* ) : (
                                <div className="position-relative">
                                  <div className="row">
                                    <div className="col-xl-12">
                                      <div className="bg-light-blue d-flex justify-content-between align-items-center px-4 py-3">
                                        <div className="d-flex align-items-center">
                                          No data found
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              )} */}
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </AppLayout>
  );
}
