/* eslint-disable */
import React, { useEffect, useState } from "react";
import AppLayout from "../Layout/AppLayout";
import { NavLink, useNavigate } from "react-router-dom";
import departmentImg from "../../assets/images/Icons/SVG/Group 22974.svg";
import NodataFound from "../comman/NodataFound";
import API from "../../Api/Api";

export default function Settings() {
  const [userList, setuserList] = useState(null);
 
  const navigate = useNavigate();

  const [selectedTab, setSelectedTab] = useState("all_users");
  const getUsersList = () => {
    setuserList([]);
    if (selectedTab) {
      API.Setting.getAllSettingListAPI({
          data: {
            [selectedTab]: true,
          },
        })
        .then((response) => {
          if (response.data.statusCode === 200) {
            // console.log(response);
            setuserList(response?.data?.data?.otherDepartments);
          }
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status === 403) {
            navigate("/");
          }
        });
    }
  };

  // const getUsersList = () => {
  //   if (selectedTab === "all") {
  //     API.Setting.getAllSettingListAPI({
  //         data: {
  //           all_users: true,
  //         },
  //       })
  //       .then((response) => {
  //         console.log(response?.data?.data?.otherDepartments);
  //         setuserList(response?.data?.data?.otherDepartments);
  //       })
  //       .catch((err) => console.log(err));
  //   } else if (selectedTab === "active") {
  //      API.Setting.getAllSettingListAPI({
  //         data: {
  //           active_users: true,
  //         },
  //       })
  //       .then((response) => {
  //         console.log(response);
  //         setactiveList(response?.data?.data?.otherDepartments);
  //       })
  //       .catch((err) => console.log(err));
  //   } else if (selectedTab === "inactive") {
  //      API.Setting.getAllSettingListAPI({
  //         data: {
  //           inactive_users: true,
  //         },
  //       })
  //       .then((response) => {
  //         console.log(response);
  //         setinactiveList(response?.data?.data?.otherDepartments);
  //       })
  //       .catch((err) => console.log(err));
  //   } else if (selectedTab === "pending") {
  //      API.Setting.getAllSettingListAPI({
  //         data: {
  //           pending_users: true,
  //         },
  //       })
  //       .then((response) => {
  //         console.log(response);
  //         setPendingList(response?.data?.data?.otherDepartments);
  //       })
  //       .catch((err) => console.log(err));
  //   }
  // };
  useEffect(() => {
    // Fetch initial data when the component mounts
    getUsersList();
  }, [selectedTab]); // Add selectedTab to the dependency array

  const handleTabClick = (tabName) => {
    setSelectedTab(tabName);
  };
  return (
    <>
      <AppLayout>
        <div className="main-content mt-4">
          <div className="page-content mb-5">
            <div className="row welcomeHeading">
              <div className="col-12">
                <h3 className="mb-0 fw-bold">Settings</h3>
              </div>
            </div>
            <h3 class="textDarkBlack my-4 fw-bold">User Management</h3>
            <div className="row">
              <div className="col-12">
                <ul
                  className="nav nav-tabs nav-tabs-custom mt-sm-5 mt-3 mt-xl-0"
                  role="tablist"
                >
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      data-bs-toggle="tab"
                      href="#all_users"
                      role="tab"
                      onClick={() => handleTabClick("all_users")}
                    >
                      <span>All Users</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      data-bs-toggle="tab"
                      href="#active_users"
                      role="tab"
                      onClick={() => handleTabClick("active_users")}
                    >
                      <span>Active Users</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      data-bs-toggle="tab"
                      href="#inactive_users"
                      role="tab"
                      onClick={() => handleTabClick("inactive_users")}
                    >
                      <span>Inactive Users</span>
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      data-bs-toggle="tab"
                      href="#pending_users"
                      role="tab"
                      onClick={() => handleTabClick("pending_users")}
                    >
                      <span>Pending Users</span>
                    </a>
                  </li>
                </ul>
                <div className="tab-content text-muted mt-3">
                  <div className="tab-pane active" id={selectedTab} role="tabpanel">
                    {userList?.length > 0 ? (
                      <div className="row mt-4">
                        {userList &&
                          userList?.map((item, index) => {
                            return (
                              <div className="col-xl-4 mb-3" key={index}>
                                <NavLink
                                  className="text-decoration-none"
                                  // to={`/settings-details/${item?._id}`}
                                >
                                  <div className="bg-light-blue d-flex justify-content-between align-items-center px-4 py-3">
                                    <div className="d-flex align-items-center">
                                      <img
                                        src={departmentImg}
                                        className="me-3 swiper-slide-img"
                                      />
                                      <div>
                                        <h3 className="textDarkBlack mb-1 fw-bold msm">
                                          {item?.dept_name}
                                        </h3>
                                        <p className="lightGrey mb-0">
                                          {item?.fullName
                                            ? item?.fullName
                                            : "-"}
                                        </p>
                                      </div>
                                    </div>
                                    {selectedTab === "pending_users" &&
                                    item?.joined === false ? (
                                      <p className="mb-0 pendingStatus">
                                        Pending
                                      </p>
                                    ) : selectedTab === "inactive_users" &&
                                      item?.isDepartmentActive === false ? (
                                      <p className="mb-0 inactiveStatus">
                                        Inactive
                                      </p>
                                    ) : selectedTab === "active_users" &&
                                      item?.isDepartmentActive === true ? (
                                      <p className="mb-0 activeStatus">
                                        Active
                                      </p>
                                    ) : selectedTab === "all_users" ? (
                                      <p
                                        className={`mb-0 ${
                                          item?.isDepartmentActive
                                            ? "activeStatus"
                                            : "inactiveStatus"
                                        }`}
                                      >
                                        {item?.isDepartmentActive
                                          ? "Active"
                                          : "Inactive"}
                                      </p>
                                    ) : null}
                                  </div>
                                </NavLink>
                              </div>
                            );
                          })}
                      </div>
                    ) : (
                      <NodataFound />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AppLayout>
    </>
  );
}
