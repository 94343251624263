import BaseApi from "./BaseApi";

const API = {
  Auth: {
    AcceptInviteGetAPI:(props)=>{
      console.log(props);
      return BaseApi({
        method: "GET",
        url: `department/accept-invite?departmentId=${props}`,
        // data: props.data,
        tokenRequired: false,
      });
    },
    manualAPI:()=>{
      return BaseApi({
        method: "GET",
        url: `department/changeDepartmentFlag`,
        // data: props.data,
        tokenRequired: true,
      });
    },
    RegisterAPI: (props) => {
      return BaseApi({
        method: "POST",
        url: `department/register_department_by_dept`,
        data: props.data,
        tokenRequired: true,
      });
    },
    OrganizationObjectiveList: () => {
      return BaseApi({
        method: "GET",
        url: `objective/get_organization_objective_dept`,
        // data: props.data,
        tokenRequired: true,
      });
    },
    OrgObjDepartmentList: () => {
      return BaseApi({
        method: "GET",
        url: `department_objective/get_department_objective`,
        // data: props.data,
        tokenRequired: true,
      });
    },
    // get single dept objective
    OrgSingleObjDepartment: (props) => {
      console.log(props);
      return BaseApi({
        method: "GET",
        url: `department_objective/get_department_objective?_id=${props?.Editid}`,
        // data: props.data,
        tokenRequired: true,
      });
    },
    UpdateOrgDepartment: (props) => {
      return BaseApi({
        method: "POST",
        url: `department_objective/update_department_objective`,
        data: props.data,
        tokenRequired: true,
      });
    },

    DeleteOrgDepartment: (props) => {
      return BaseApi({
        method: "POST",
        url: `department_objective/deactivate_department_objective`,
        data: props.data,
        tokenRequired: true,
      });
    },

    // Key result API starts
    GetALlKeyResultAPI: () => {
      return BaseApi({
        method: "GET",
        url: `key_result/get_key_result`,
        // data: props.data,
        tokenRequired: true,
      });
    },
    UpdateKeyResultAPI: (props) => {
      return BaseApi({
        method: "POST",
        url: `key_result/update_key_result`,
        data: props.data,
        tokenRequired: true,
      });
    },

    DeleteKeyResultAPI: (props) => {
      return BaseApi({
        method: "POST",
        url: `key_result/deactivate_key_result`,
        data: props.data,
        tokenRequired: true,
      });
    },
    // Key result API ends
    LoginAPI: (props) => {
      return BaseApi({
        method: "POST",
        url: `department/login_department`,
        data: props.data,
        tokenRequired: false,
      });
    },
    VerifyOTP: (props) => {
      return BaseApi({
        method: "POST",
        url: `department/verifyDepartmentOtp`,
        data: props.data,
        tokenRequired: false,
      });
    },
    ResendOtp: (props) => {
      return BaseApi({
        method: "POST",
        url: `department/resendDepartmentOtp`,
        data: props.data,
        tokenRequired: false,
      });
    },
    DownloadExcel: () => {
      return BaseApi({
        method: "POST",
        url: `department_objective/download_xl_file`,
        // data: props.data,
        tokenRequired: true,
      });
    },
    UploadExcel: (props) => {
      return BaseApi({
        method: "POST",
        url: `department_objective/save_deptData_by_excel`,
        data: props.data,
        tokenRequired: true,
      });
    },

  },

  Profile: {
    GetProfileDetails: () => {
      return BaseApi({
        method: "GET",
        url: `department/get_department_profile`,
        // data: props?.data,
        tokenRequired: true,
      });
    },
    uploadProfileImg: () => {
      return BaseApi({
        method: "POST",
        url: `organization/fileUpload`,
        // data: props?.data,
        tokenRequired: false,
      });
    },
    UpdateProfileImage: (props) => {
      return BaseApi({
        method: "POST",
        url: `/department/update_department_by_dept`,
        data: props?.data,
        tokenRequired: true,
      });
    },
  },
  Dashboard: {
    DashboardOrgObjectiveList: () => {
      return BaseApi({
        method: "GET",
        url: `department/getOrganization_with_objectives`,
        // data: props.data,
        tokenRequired: true,
      });
    },
    getDashboardData : (props)=>{
      return BaseApi({
        method: "GET",
        url: `/department/get_dashboard_details?start_date=${props.sDate}&end_date=${props.EDate} `,
        // data: props.data,
        tokenRequired: true,
      });
    },
    getDepartmentYears: () => {
      return BaseApi({
        method: "GET",
        url: `department/get_org_year_quarter`,
        // data: props.data,
        tokenRequired: true,
      });
    },
  },
  Setting: {
    getAllSettingListAPI: (props) => {
      return BaseApi({
        method: "POST",
        url: `/department/department_list_by_status`,
        data: props?.data,
        tokenRequired: true,
      });
    },
  },
  Department: {
    getDepartmentListWithProgress: () => {
      return BaseApi({
        method: "GET",
        url: `department/department_progress_list`,
        // data: props.data,
        tokenRequired: true,
      });
    },
    getDepartmentObjectiveList: (props) => {
      return BaseApi({
        method: "GET",
        url: `department/get_objectives_list?departmentId=${props}`,
        // data: props.data,
        tokenRequired: true,
      });
    },
    getDepartmentKeyResultList: (props) => {
      console.log(props);
      return BaseApi({
        method: "GET",
        url: `key_result/key_result_score_list_by_dept?department_objective_id=${props}`,
        // data: props.data,
        tokenRequired: true,
      });
    },
    UpdateCurrentScore: (props) => {
      return BaseApi({
        method: "POST",
        url: `logs_score/update_logs_score`,
        data: props.data,
        tokenRequired: true,
      });
    },
    createDepartment:(props)=>{
      return BaseApi({
        method: "POST",
        url: `department_objective/create_department_objective`,
        data: props.data,
        tokenRequired: true,
      });
    },
    createTaskDepartment:(props)=>{
      return BaseApi({
        method: "POST",
        url: `key_result/create_key_result`,
        data: props.data,
        tokenRequired: true,
      });
    },
    getDepartmentOwners: () => {
      return BaseApi({
        method: "GET",
        url: `department/get_departments_by_dept`,
        // data: props.data,
        tokenRequired: true,
      });
    },
  },
};

export default API;
