import React from 'react'

export default function NodataFound() {
  return (
    <div> <div className="row mt-4">
    <div className="col-xl-12 mb-3">
      <div className="bg-light-blue d-flex justify-content-between align-items-center px-4 py-3">
        <div className="d-flex align-items-center">
          No data found
        </div>
      </div>
    </div>
  </div></div>
  )
}
