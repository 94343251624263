import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  sDate: "",
  EDate: "",
  departmentData: {},
  objectiveInfo: {},
  userdata: {},
  errorData: "",
};

export const counterSlice = createSlice({
  name: "date",
  initialState,
  reducers: {
    userdata: (state, action) => {
      state.userdata = action.payload;
    },
    errorData: (state, action) => {
      state.errorData = action.payload;
    },
    yearData: (state, action) => {
      return {
        ...state,
        sDate: action.payload.sDate,
        EDate: action.payload.EDate,
      };
    },

    DepartmentData: (state, action) => {
      state.departmentData = action.payload;
    },
    setObjectiveData: (state, action) => {
      state.objectiveInfo = action.payload;
    
    },
  },
});

// Action creators are generated for each case reducer function
export const { userdata, errorData,yearData, DepartmentData ,setObjectiveData } = counterSlice.actions;

export default counterSlice.reducer;
