import React from "react";
import RouteName from "./Routename";
import Login from "../Components/Auth/Login";
import Signup from "../Components/Auth/Signup";
import Registration from "../Components/Auth/Registration";
import Department from "../Components/Department/Department";
import DepartmentDetails from "../Components/Department/DepartmentDetails";
import TimelyDelivery from "../Components/Department/TimelyDelivery";

import VerifyOtp from "../Components/Auth/VerifyOtp";
import Dashboard from "../Components/Dashboard/Dashboard";
import MyProfile from "../Components/MyProfile/MyProfile";
import Organization from "../Components/Organization/Organization";
import Settings from "../Components/Settings/Settings";
import SettingsDetails from "../Components/Settings/SettingsDetails"
import SettingsDetailsEdit from "../Components/Settings/SettingsDetailsEdit"
import ProtectedRoute from '../Routes/ProtectedRoute'

const ConsumerRoutes = [
  {
    path: RouteName.Auth.Login,
    element: <Login />,
    exact: true,
  },
  {
    path: RouteName.Auth.SignUp,
    element: <Signup />,
    exact: true,
  },
  {
    path: RouteName.Auth.VerifyOtp,
    element: <VerifyOtp />,
    exact: true,
  },
  {
    path: RouteName.Auth.Registration,
    element: <Registration />,
    exact: true,
  },
  {
    path: RouteName.Department.Department,
    element: <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}><Department /></ProtectedRoute>,
    exact: true,
  },
  {
    path: RouteName.Department.DepartmentDetails,
    element: <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}><DepartmentDetails /></ProtectedRoute>,
    exact: true,
  },
  {
    path: RouteName.Department.TimelyDelivery,
    element:<ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}> <TimelyDelivery /></ProtectedRoute>,
    exact: true,
  },
  
  {
    path: RouteName.Dashboard.Dashboard,
    element: <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}> <Dashboard /></ProtectedRoute>,
    exact: true,
  },
  {
    path: RouteName.MyProfile.MyProfile,
    element: <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}> <MyProfile /></ProtectedRoute>,
    exact: true,
  },
  {
    path: RouteName.Organization.Organization,
    element: <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}> <Organization /></ProtectedRoute>,
    exact: true,
  },
  {
    path: RouteName.Settings.Settings,
    element: <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}> <Settings /></ProtectedRoute>,
    exact: true,
  },
  {
    path: RouteName.Settings.SettingsDetails,
    element: <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}> <SettingsDetails /></ProtectedRoute>,
    exact: true,
  },
  {
    path: RouteName.Settings.SettingsDetailsEdit,
    element: <ProtectedRoute redirectPath={`../${RouteName.Auth.Login}`}> <SettingsDetailsEdit /></ProtectedRoute>,
    exact: true,
  },
  

];

export default ConsumerRoutes;
