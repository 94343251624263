/* eslint-disable */
import React, { useState, useEffect } from "react";
import setObjective from "../../../assets/images/Icons/Pics/Set Objective.png";
import improveProductivity from "../../../assets/images/Icons/SVG/Selected Org.svg";
import timelyDelivery from "../../../assets/images/Icons/Pics/Department/SLA.png";
import editIcon from "../../../assets/images/Icons/SVG/Edit Icon.svg";
import deleteIcon from "../../../assets/images/Icons/SVG/Delete Icon.svg";
import API from "../../../Api/Api";
import { useNavigate } from "react-router-dom";
import RouteName from "../../../Routes/Routename";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const SetObjective = (props) => {
  const [objectiveList, setObjectiveList] = useState(null);
  const [DepartmentList, setDepartmentList] = useState(null);
  const [Editid, setEditID] = useState(null);
  const [isEditing, setEditing] = useState(false); // New state for edit mode
 
  const [deleteId, setDeleteId] = useState();
  const navigate = useNavigate();

  const getOrganizationalList = () => {
    API.Auth.OrganizationObjectiveList()
      .then((response) => {
        if (response.data.statusCode === 200) {
          console.log(response);
          setObjectiveList(response.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getOrganizationalList();
    getAllDeptList();
  }, []);
  const validate = (values) => {
    console.log(values, "value");
    const spaceReg = /^\S.*\S$/;
    const paragraphReg=/^[^\s]+(\s+[^\s]+)*$/

    const errors = {};
    if (!values.dept_objective_name) {
      errors.dept_objective_name = "Please enter department objective  name";
    } else if (!spaceReg.test(values.dept_objective_name)) {
      errors.dept_objective_name = "Cannot start & end with a space";
    }
    if (!values.dept_objective_description) {
      errors.dept_objective_description =
        "Please enter department objective description";
    } else if (!paragraphReg.test(values.dept_objective_description)) {
      errors.dept_objective_description = "Cannot start & end with a space";
    }
    if (!values.objective_id) {
      errors.objective_id = "Please select department objective";
    }

    console.log("Erroes", errors);
    return errors;
  };
  const formik = useFormik({
    initialValues: {
      dept_objective_name: "",
      dept_objective_description: "",
      objective_id: "",
    },
    onSubmit: (values) => {
      console.log(values, "hello values");
      if (Editid) {
        handleUpdate();
      } else {
        createDeptObjective();
      }
    },
    validate,
  });

  const createDeptObjective = () => {
    API.Auth.RegisterAPI({
      data: {
        objective_id: formik.values.objective_id,
        dept_objective_name: formik.values.dept_objective_name,
        dept_objective_description: formik.values.dept_objective_description,
        flag: 3,
      },
    })
      .then((response) => {
        if (response.data.statusCode === 200) {
          console.log(response);
          formik.resetForm();
          getAllDeptList();
       
        } else {
          toast.error(response?.data?.message);

        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // get all Department list

  const getAllDeptList = () => {
    API.Auth.OrgObjDepartmentList()
      .then((response) => {
        if (response.data.statusCode === 200) {
          setDepartmentList(response.data.data);
          console.log(response);
        }
      })
      .catch((err) => console.log(err));
  };
  const handleEdit = (id) => {
    const objectiveToEdit = DepartmentList.find((item) => item._id === id);
    console.log(objectiveToEdit);
    if (objectiveToEdit) {
      formik.setValues({
        dept_objective_name: objectiveToEdit.dept_objective_name,
        dept_objective_description: objectiveToEdit.dept_objective_description,
        objective_id: objectiveToEdit.objective_id,
      });
      setEditing(true);
      setEditID(id);
    }
  };
  console.log(Editid, "Editid");
  // Update api
  const handleUpdate = () => {
    const objectiveToEdit = DepartmentList.find((item) => item._id === Editid);

    if (
      objectiveToEdit.dept_objective_description ===
        formik.values.dept_objective_description &&
      objectiveToEdit.dept_objective_name ===
        formik.values.dept_objective_name &&
      objectiveToEdit.objective_id === formik.values.objective_id
    ) {
      formik.resetForm();
      setEditing(false);
      setEditID("");
    } else {
      API.Auth.UpdateOrgDepartment({
        data: {
          _id: Editid,
          objective_id: formik.values.objective_id,
          dept_objective_name: formik.values.dept_objective_name,
          dept_objective_description: formik.values.dept_objective_description,
        },
      })
        .then((response) => {
          if (response?.data?.statusCode === 200) {
          console.log("response", response.data);
          getAllDeptList();
          formik.resetForm();
          setEditing(false);
          console.log("Objective Successfully Updated");
          }else {
            // Handle error or provide feedback to the user
            toast.error(response?.data?.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleDelete = (id) => {
    setDeleteId(id);
  };


  // Deelete API
  const deleteDepartment = () => {
    try {
      API.Auth.DeleteOrgDepartment({
        data: { _id: deleteId },
      }).then((response) => {
        const newObjectiveId = response.data.data._id;
        const updatedArray = DepartmentList.filter(
          (item) => item._id !== newObjectiveId
        );
        setDepartmentList(updatedArray);
        document.querySelector("#DeleteDepartemnt").click();
        formik.resetForm();
        setEditing(false);
        setEditID("");
        console.log("Objective Successfully Deleted");
      });
    } catch (error) {
      console.log(error);
    }
  };
  const handleCancel = (event) => {
    event.preventDefault();
    formik.resetForm();
    setEditing(false);
  };
  const handleNextButton = () => {
    props.handleNext();

    navigate(`../${RouteName.Auth.Registration}?id=3`, { replace: true });
  };
  return (
    <div className="mt-4">
      <div className="row justify-content-center mt-4">
        <div className="col-xxl-11 col-12">
          <h2 className="textBlue fw-normal">Define Department</h2>
          <p className="textGrey">
            Make sure your objectives are crisp and clear
          </p>
          <div className="row">
            <div className="col-xl-6 pe-xl-5 pb-5 border-right">
              <p className="textBlueLight">
                Select organizational objective and set your objective
              </p>
              {/* <p className="textLightGrey">Objective 1</p> */}
              <form>
                <div className="mb-4">
                  <div className="form-group">
                    <select
                      class="form-select form-control"
                      aria-label="Default select example"
                      id="definedepartment"
                      // onChange={(e) => getObjectiveId(e.target.value)}
                      name="objective_id"
                      onChange={formik.handleChange}
                      value={formik.values.objective_id}
                    >
                      <option selected>Select organizational objective</option>

                      {objectiveList &&
                        objectiveList.map((item) => {
                          return (
                            <option key={item?._id} value={item?._id}>
                              {item?.objective_name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                  {formik.errors.objective_id && formik.touched.objective_id ? (
                    <div className="text-danger">
                      {formik.errors.objective_id}
                    </div>
                  ) : null}
                </div>
                <div className="mb-4">
                  <div className="form-group">
                    <input
                      type="text"
                      name="dept_objective_name"
                      // value={formValues.name}
                      // onChange={handleChange}
                      maxLength="30"
                      className="form-control mt-2"
                      placeholder="Eg: Timely Delivery"
                      id="exampleInputfirstname"
                      value={formik.values.dept_objective_name}
                      onChange={formik.handleChange}
                    />
                  </div>
                  {formik.errors.dept_objective_name &&
                  formik.touched.dept_objective_name ? (
                    <div className="text-danger">
                      {formik.errors.dept_objective_name}
                    </div>
                  ) : null}
                </div>
                <div className="mb-4">
                  <div className="form-group">
                    <textarea
                      className="form-control"
                      name="dept_objective_description"
                      placeholder="
                      Description of objective"
                      id="floatingTextarea"
                      rows="4"
                      maxLength="100"
                      value={formik.values.dept_objective_description}
                      onChange={formik.handleChange}
                    ></textarea>
                  </div>
                  {formik.errors.dept_objective_description &&
                  formik.touched.dept_objective_description ? (
                    <div className="text-danger">
                      {formik.errors.dept_objective_description}
                    </div>
                  ) : null}
              
                </div>
                {!isEditing ? (
                  <div className="text-center">
                    <button
                      type="submit"
                      className="btn set-objective-button w-100"
                      onClick={formik.handleSubmit}
                    >
                      Publish
                    </button>
                  </div>
                ) : (
                  <div className="d-flex justify-content-between">
                    <button
                      type="submit"
                      className="btn set-objective-button px-5"
                      onClick={(event) => handleCancel(event)}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="btn sign-up-button-color text-white px-5"
                      onClick={formik.handleSubmit}
                    >
                      Update
                    </button>
                  </div>
                )}
              </form>
            </div>
            <div className="col-xl-6 ps-xl-5">
              <p className="textBlueLight">
                Here are the list of your departments and their departmental
                leaders
              </p>
              {DepartmentList?.length == 0 ? (
                <div className="row">
                  <div className="col-12 d-flex justify-content-center align-items-center py-5">
                    <img src={setObjective} />
                  </div>
                </div>
              ) : (
                <>
                  {DepartmentList &&
                    DepartmentList.map((item, index) => {
                      console.log(item);
                      return (
                        <div
                          className="row d-flex align-items-center mb-1"
                          key={index}
                        >
                          <div className="col-9">
                            <div className="setObjectiveListBox p-3">
                              <div className=" row">
                                <div className="col-2">
                                  <img
                                    src={improveProductivity}
                                    className="Width-20 mb-2"
                                  />
                                  <br />
                                  <img
                                    src={timelyDelivery}
                                    className="Width-20"
                                  />
                                </div>
                                <div className="col-10 setObjectiveListBox">
                                  <h4 className="textBlueLight1 mb-2">
                                    {item?.objective_name}
                                  </h4>
                                  <h5 className="textBlueLight1">
                                    {item?.dept_objective_name}
                                  </h5>
                                  <p className="textSecondary">
                                    {item?.dept_objective_description}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-3">
                            <div className="d-flex">
                              <img
                                src={editIcon}
                                onClick={() => handleEdit(item?._id)}
                              />
                               <button
                              type="button"
                              className="btn"
                              data-bs-toggle="modal"
                              id="DeleteDepartemnt"
                              data-bs-target="#staticBackdrop1"
                              onClick={() => handleDelete(item._id)}
                            >
                              <img src={deleteIcon} />
                            </button>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </>
              )}
              <button
                className={`btn ${
                  DepartmentList?.length === 0
                    ? "sign-up-button-color-onempty"
                    : "sign-up-button-color"
                } text-light w-100 mt-3`}
                onClick={() => handleNextButton()}
                disabled={DepartmentList?.length === 0}
              >
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
         {/* delete modal
       */}
      <div
        className="modal fade"
        id="staticBackdrop1"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        // tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered bg-transparent selfModelWidth deleteModalC">
          <div className="modal-content border-0 p-5">
            <div className="modal-body text-start text-center">
              <h2 className="textGrey">
                Are you sure you want to delete this?
              </h2>
              <div className="d-flex justify-content-center mt-3">
                <button
                  className="btn btnOrganization w-auto px-5 mt-3 me-3 "
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel
                </button>
                <button
                  className="btn sign-up-button-color text-white px-5 mt-3"
                  onClick={() => deleteDepartment()}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SetObjective;
