import axios from "axios";
import baseApi from "./config";

const BaseApi = (props) => {
  // Admin Object
  const adminObject = localStorage.getItem("DepartmentToken");
// console.log(localStorage.getItem("DepartmentToken"),adminObject);

  // const adminObject =
  //   "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJfaWQiOiI2NWE1MTQyZWZhOTAyYTk1ODc0NjJlZDEiLCJlbWFpbCI6InJhbmlwYWl0aGFua2FyMTRAZ21haWwuY29tIiwiaWF0IjoxNzA1MzE3NjA4LCJleHAiOjE4NzgxMTc2MDh9.wZde6bcxKpuKVXlQZsFhoM0ULEXyzfdCj4gzco8Pui8";
  // Request Configuration
  const configuration = {
    method: props.method,
    url: props.url,
    // baseURL: `${process.env.REACT_APP_BASE_URL}/vidyame/dev/api`,
    baseURL: baseApi.baseurl,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    tokenRequired: props.tokenRequired,
  };
  // Request Data
  if (props.method === "POST") {
    configuration.data = props.data;
  }

  // Request Params
  if (props.method === "GET") {
    configuration.params = props.params;
  }

  // Request Header Authorization
  if (props.tokenRequired) {
    if (adminObject) {
      configuration.headers.Authorization =
        adminObject && `bearer ${adminObject}`;
    } else {
      configuration.headers.Authorization =
        props.token && `bearer ${props.token}`;
    }
  }

  // Return Request
  return axios(configuration);
};

export default BaseApi;
