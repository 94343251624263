import React, { useEffect, useState } from "react";
import AppLayout from "../Layout/AppLayout";
import { NavLink,useNavigate } from "react-router-dom";
import departmentImg from "../../assets/images/Icons/SVG/Group 22974.svg";
import API from "../../Api/Api";
import "react-circular-progressbar/dist/styles.css";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import { useDispatch } from "react-redux";
import { DepartmentData } from "../../Redux/Slice";
export default function Department() {
  const [progressList, setProgressLIst] = useState(null);
  const dispatch = useDispatch();
  const navigate=useNavigate();

  const getDepartmentProgressList = () => {
    API.Department.getDepartmentListWithProgress()
      .then((response) => {
        if (response.data.statusCode === 200) {
          console.log(response.data.data);
          setProgressLIst(response.data.data);
        }
      })
      .catch((err) => {
        if(err.response.status===403){
          navigate("/");
          }
        console.log(err);
      });
  };
  useEffect(() => {
    getDepartmentProgressList();
  }, []);
  console.log(progressList?.user);

  const NeededData = (id, name, img, fullname) => {
    console.log(id, name, img, fullname, "data");
    const newdata = {
      id: id,
      DeptName: name,
      DeptImage: img ? img : "",
      fullname: fullname ? fullname : "-",
    };
    console.log(newdata, "newdatanewdatanewdata");
    dispatch(DepartmentData(newdata));
  };
  return (
    <AppLayout>
      <div className="main-content mt-3">
        <div className="page-content mb-5">
          <div className="row welcomeHeading">
            <div className="col-12">
              <h3 className="mb-0 fw-bold">Department</h3>
            </div>
          </div>

          <div className="row my-4">
            <div className="col-lg-12">
              <div className="bg-light-blue px-4 py-3">
                <div className="row">
                  <div className="col-4">
                    <h4 className="textDarkBlack mb-4">My Department</h4>
                    <div className="d-flex align-items-center">
                      <img
                        src={departmentImg}
                        className="me-3 swiper-slide-img"
                      />
                      {/* <img
                            src={
                              item?.profileImageUrl
                                ? `${process.env.REACT_APP_BASE_URL}/images/${item?.profileImageUrl}`
                                : departmentImg
                            }
                            className="me-3 Organization-img"
                          /> */}
                      <div>
                        <h3 className="textDarkBlack mb-1 fw-bold msm">
                          {progressList &&
                            progressList?.user &&
                            progressList?.user?.dept_name}
                        </h3>

                        <p className="lightGrey mb-0">
                          {progressList &&
                            progressList?.user &&
                            progressList?.user?.fullName}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-5">
                    <h4 className="textDarkBlack mb-3">Over all Performance</h4>
                    <div className="row align-items-center">
                      <div className="col-2">
                        <h3 className="textBlueLight fw-bold mb-0">
                          {" "}
                          {progressList &&
                            progressList?.user &&
                            Math.round(progressList?.user?.dept_growth * 100) /
                              100}
                          %
                        </h3>
                      </div>
                      <div className="col-10">
                        <div className="progress">
                          <div
                            className="progress-bar"
                            style={{
                              width: `${
                                progressList &&
                                progressList?.user &&
                                progressList?.user?.dept_growth
                              }%`,
                            }}
                            role="progressbar"
                            aria-valuenow={
                              progressList &&
                              progressList?.user &&
                              progressList?.user?.dept_growth
                            }
                            aria-valuemin="0"
                            aria-valuemax="100"
                          ></div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-3 text-end">
                    <NavLink
                      className="btn set-objective-button px-5 mt-4 font-size-14"
                      to={`/department-details/${progressList?.user?._id}/user`}
                      onClick={() =>
                        NeededData(
                          progressList?.user?._id,
                          progressList?.user?.dept_name,
                          progressList?.user?.profileImageUrl,
                          progressList?.user?.fullname
                        )
                      }
                    >
                      View Details
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row my-4">
            <div className="col-lg-6">
              <h4 className="textDarkBlack">Departments and their progress</h4>
            </div>
          </div>

          <div className="row">
            {progressList &&
              progressList?.departmentList &&
              progressList?.departmentList.map((item, index) => {
                return (
                  <div className="col-xxl-4 col-lg-6 col-12 mb-3" key={index}>
                    {/* <NavLink
                      className="text-decoration-none"
                      to={`/department-details/${progressList?.user?._id}/Dept`}
                      onClick={() =>
                        NeededData(
                          progressList?.user?._id,
                          progressList?.user?.dept_name,
                          progressList?.user?.profileImageUrl,
                          progressList?.user?.fullname
                        )
                      }
                    > */}
                      <div className="bg-light-blue d-flex justify-content-between align-items-center px-4 py-2">
                        <div className="d-sm-flex align-items-center">
                          {/* <img
                            src={departmentImg}
                            className="me-3 swiper-slide-img"
                          /> */}
                          <img
                            src={
                              item?.profileImageUrl
                                ? `${process.env.REACT_APP_BASE_URL}/images/${item?.profileImageUrl}`
                                : departmentImg
                            }
                            className="me-3 Organization-img"
                          />
                          <div>
                            <h3 className="textDarkBlack mb-1 fw-bold msm">
                              {item?.dept_name}{" "}
                            </h3>
                            <p className="lightGrey mb-0"> {item?.fullName}</p>
                          </div>
                        </div>
                        <div style={{ width: "100px", height: "100px" }}>
                          <CircularProgressbar
                            value={item?.dept_growth}
                            text={`${
                              Math.round(item?.dept_growth * 100) / 100
                            }%`}
                            strokeWidth={10}
                            styles={buildStyles({
                              rotation: 0.0,
                              strokeLinecap: "butt",
                              textSize: "20px",
                              className: "bold-text",
                              pathTransitionDuration: 0.5,
                              pathColor: `#6993ff, ${item?.dept_growth / 100})`,
                              textColor: "#6993ff",
                              trailColor: "#d6d6d6",
                              backgroundColor: "#3e98c7",
                            })}
                          />
                        </div>
                      </div>
                    {/* </NavLink> */}
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </AppLayout>
  );
}
