/* eslint-disable */
import React, { useEffect, useState } from "react";
import devider from "../../assets/images/SVG/deviderline.svg";
import LoginBanner from "../comman/LoginBanner";
import { useFormik } from "formik";
import { signUpSchema } from "../../schema";
// import { toast } from "react-toastify";
//import "react-toastify/dist/ReactToastify.css";
import { NavLink, useNavigate } from "react-router-dom";
import VerifyOtp from "./VerifyOtp";
import { useDispatch, useSelector } from "react-redux";
import { errorData } from "../../Redux/Slice";

export default function Signup() {
  const [ShowOtpModal, setShowOtpModal] = useState(true);
  const [SignUpResponseData, setSignupResponseData] = useState("");
  const errorMessageData = useSelector((state) => state.counter.errorData);
  const dispatch = useDispatch();
  const initialValues = {
    firstName: "",
    lastName: "",
    email: "",
  };

  const { values, errors, handleChange, handleSubmit, handleBlur, touched } =
    useFormik({
      initialValues,
      validationSchema: signUpSchema,
      validateOnChange: true,
      validateOnBlur: false,

      onSubmit: (values) => {
        console.log("values", values);
        console.log("errors", errors);
        API.Auth.SignupAPI({
          data: {
            first_name: values?.firstName,
            last_name: values?.lastName,
            email: values?.email,
            is_admin: 0,
            role: 1,
          },
        })
          .then(function (response) {
            console.log(response, "response");
            if (response?.data?.status === 200) {
              console.log(response, "response");
              setSignupResponseData(response?.data);
              dispatch(errorData(""));

              // toast.success(response?.data?.message);
              setTimeout(() => {
                setShowOtpModal(false);
              }, 2000);
            } else {
              console.log(response?.data?.message);

              dispatch(errorData(response?.data?.message));

              setTimeout(() => {
                dispatch(errorData(""));
              }, 3000);
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      },
    });

  return (
    <div className="login-page">
      <div className="container-fluid main-container vh-100">
        <div className="row">
          {/* Left Side Banner */}
          <div className="col-lg-6 col-12 vh-100 loginBanner">
            <div className="row d-flex align-items-end h-100">
              <div className="ps-5 d-flex flex-column justify-content-between align-items-start h-100 py-5">
                <div>
                  <a to="/" className="text-decoration-none">
                    <svg
                      id="Component_96_37"
                      className="login-icon"
                      data-name="Component 96 – 37"
                      xmlns="http://www.w3.org/2000/svg"
                      width="202.377"
                      height="44.829"
                      viewBox="0 0 202.377 44.829"
                    >
                      <defs>
                        <clipPath id="clip-path">
                          <rect
                            id="Rectangle_6408"
                            data-name="Rectangle 6408"
                            width="144.348"
                            height="25.111"
                            fill="#fef3e4"
                          />
                        </clipPath>
                        <clipPath id="clip-path-2">
                          <rect
                            id="Rectangle_5902"
                            data-name="Rectangle 5902"
                            width="43.611"
                            height="44.829"
                            fill="#fef3e4"
                          />
                        </clipPath>
                      </defs>
                      <g
                        id="Group_16363"
                        data-name="Group 16363"
                        transform="translate(58.029 9.007)"
                        clipPath="url(#clip-path)"
                      >
                        <path
                          id="Path_11345"
                          data-name="Path 11345"
                          d="M27.106.393c-.041,0-.076.005-.116.006h-.025c-1.19.037-1.79.522-1.79,1.465V23.6q0,1.472,1.932,1.472,1.964,0,1.965-1.472V3.028h0V.393L27.211.4c-.037,0-.068-.005-.105-.005"
                          transform="translate(-2.096 -0.056)"
                          fill="#fef3e4"
                        />
                        <path
                          id="Path_11346"
                          data-name="Path 11346"
                          d="M51.729,1.767h.033A10.02,10.02,0,0,0,49.994.908,5.108,5.108,0,0,0,48.226.571H37.913l-.018,0H35.84V6.266l.01,0V23.658q0,1.472,1.932,1.472a2.3,2.3,0,0,0,1.375-.4,1.253,1.253,0,0,0,.556-1.073l.033-19.991H47.6a2.014,2.014,0,0,1,1.7.644,2.277,2.277,0,0,1,.491,1.411V19.765a2.317,2.317,0,0,1-.491,1.441,2.014,2.014,0,0,1-1.7.644H42.955q-1.408,0-1.408,1.533a1.884,1.884,0,0,0,.312,1.134,1.007,1.007,0,0,0,.867.429h5.5a5.185,5.185,0,0,0,1.751-.322,7.683,7.683,0,0,0,1.752-.9A4.212,4.212,0,0,0,53.5,20.01V5.447a4.842,4.842,0,0,0-.459-2.161,3.877,3.877,0,0,0-1.309-1.519"
                          transform="translate(-2.984 -0.081)"
                          fill="#fef3e4"
                        />
                        <path
                          id="Path_11347"
                          data-name="Path 11347"
                          d="M93.23,17.607a2.459,2.459,0,0,0-1.768.69,2.3,2.3,0,0,0,0,3.341,2.459,2.459,0,0,0,1.768.69,2.513,2.513,0,0,0,1.784-.69,2.262,2.262,0,0,0,0-3.341,2.513,2.513,0,0,0-1.784-.69"
                          transform="translate(-7.554 -2.491)"
                          fill="#fef3e4"
                        />
                        <path
                          id="Path_11348"
                          data-name="Path 11348"
                          d="M99.714,12.428q-1.566-4.449-3.094-8.84L95.564.394h-2.49c-1.557,0-3.472-.022-4.058,1.617-.21.589-.413,1.18-.621,1.769Q87.849,5.337,87.3,6.894l-1.37,3.9-1.447,4.119-1.328,3.78L82.145,21.57l-.5,1.42-.022.062q-.491,1.41,1.375,1.962h-.032c.174.041.344.076.507.107a2.734,2.734,0,0,0,.508.046,1.246,1.246,0,0,0,.834-.292,1.988,1.988,0,0,0,.541-.75v.03l7.17-20.6,7.2,20.6v-.03a1.607,1.607,0,0,0,.54.75,1.332,1.332,0,0,0,.835.292,2.233,2.233,0,0,0,.474-.046c.142-.031.3-.066.475-.107q1.833-.551,1.375-1.962-1.833-5.273-3.716-10.624"
                          transform="translate(-6.787 -0.056)"
                          fill="#fef3e4"
                        />
                        <path
                          id="Path_11349"
                          data-name="Path 11349"
                          d="M148.989,11.95a2.475,2.475,0,0,0,0,3.618,2.677,2.677,0,0,0,1.9.736,2.773,2.773,0,0,0,1.947-.736,2.441,2.441,0,0,0,0-3.618,2.777,2.777,0,0,0-1.947-.736,2.681,2.681,0,0,0-1.9.736"
                          transform="translate(-12.338 -1.587)"
                          fill="#fef3e4"
                        />
                        <path
                          id="Path_11350"
                          data-name="Path 11350"
                          d="M154.821,21.849H144.638V3.667h9.593c.814,0,1.235-.435,1.272-1.3h0V.565h-2.688V.57h-9.982a1.949,1.949,0,0,0-1.637.567,2.067,2.067,0,0,0-.425,1.272V23.075a2.124,2.124,0,0,0,.425,1.287,1.918,1.918,0,0,0,1.637.583h11.983q1.309,0,1.244-1.594-.033-1.5-1.244-1.5"
                          transform="translate(-11.72 -0.08)"
                          fill="#fef3e4"
                        />
                        <path
                          id="Path_11351"
                          data-name="Path 11351"
                          d="M16.9.36l-.041,0A1.388,1.388,0,0,0,15.449,1.4q-.655,1.993-1.406,4.277t-1.524,4.615q-.769,2.33-1.505,4.584t-1.36,4.184Q9.262,17.9,8.8,16.53t-.917-2.744q-.459-1.381-.9-2.7t-.77-2.422L3.794,1.4A1.375,1.375,0,0,0,2.354.357,3.646,3.646,0,0,0,1.5.479,2.52,2.52,0,0,0,.291,1.23a1.2,1.2,0,0,0-.229,1.18q.914,2.822,1.965,5.9t1.965,5.933q.818,2.422,1.636,4.936T7.2,24.088a1.672,1.672,0,0,0,1.063.751,5.332,5.332,0,0,0,1.391.2,6.088,6.088,0,0,0,1.425-.169,1.315,1.315,0,0,0,.966-.782q1.768-5.4,3.568-10.853,1.781-5.4,3.532-10.713L19.87.353Z"
                          transform="translate(0 -0.05)"
                          fill="#fef3e4"
                        />
                        <path
                          id="Path_11352"
                          data-name="Path 11352"
                          d="M75.93,0a1.314,1.314,0,0,0-.763.215A2.319,2.319,0,0,0,74.595.8q-.688,1.2-1.441,2.545T71.632,6.071q-.77,1.379-1.523,2.713t-1.408,2.5L62.743.8a1.438,1.438,0,0,0-1.31-.8,3.808,3.808,0,0,0-1.146.245h.033q-1.114.43-1.113,1.319a1.844,1.844,0,0,0,.2.828l7.334,13.062v8.156q0,1.471,1.932,1.472T70.6,23.609V15.545q2-3.588,3.274-5.871t2.079-3.71q.8-1.426,1.244-2.224t.736-1.349a.851.851,0,0,0,.041-.082l.006,0L79.239,0H75.93Z"
                          transform="translate(-4.929 0)"
                          fill="#fef3e4"
                        />
                        <path
                          id="Path_11353"
                          data-name="Path 11353"
                          d="M129.441.4l-.042,0a4.25,4.25,0,0,0-1,.123,4.522,4.522,0,0,0-.966.353,3.427,3.427,0,0,0-.818.567,2.419,2.419,0,0,0-.557.766q-.557,1.84-1.195,3.971t-1.294,4.308q-.655,2.178-1.276,4.293T121.116,18.7l-5.14-16.4a2.508,2.508,0,0,0-.556-.813,4.721,4.721,0,0,0-4.546-.828c-.887.261-1.734.854-1.74,1.8V23.6q0,1.472,1.932,1.472T113,23.6V8.2a.533.533,0,0,1,.479-.515.56.56,0,0,1,.608.368q.773,2.416,1.435,4.431.719,2.193,1.44,4.308l2.358,7.144a1.376,1.376,0,0,0,.622.8,2.273,2.273,0,0,0,1.081.307A1.6,1.6,0,0,0,122.785,24l3.929-12.264,1.175-3.578a.555.555,0,0,1,.587-.363.532.532,0,0,1,.5.515V23.6q0,1.472,1.932,1.472t1.932-1.472V.393l-3.4,0"
                          transform="translate(-9.085 -0.056)"
                          fill="#fef3e4"
                        />
                      </g>
                      <g
                        id="Group_13740"
                        data-name="Group 13740"
                        clipPath="url(#clip-path-2)"
                      >
                        <path
                          id="Path_10356"
                          data-name="Path 10356"
                          d="M43.106,6.289A27.1,27.1,0,0,0,26.414.008C26.207,0,26,0,25.791,0A27.163,27.163,0,0,0,9.209,5.675,22.526,22.526,0,0,0,3.89,11.56,21.035,21.035,0,0,0,6.6,39.059a18.742,18.742,0,0,0,6.327,4.3,19,19,0,0,0,7.344,1.462h.162c.2.006.417.009.641.009A21.1,21.1,0,0,0,42.149,23.752c0-.091,0-.181,0-.272a.393.393,0,0,0,.006-.082,1.257,1.257,0,0,0-.008-.145.017.017,0,0,0,0-.011,20.942,20.942,0,0,0-3.162-10.589l-.034-.059,4.225-4.182a1.108,1.108,0,0,0,.13-.145l.031-.043c.02-.025.045-.063.071-.1l.023-.037.037-.076c.02-.039.037-.079.054-.113l.023-.068.037-.13a1.415,1.415,0,0,0-.468-1.408m-3.8,17.463a18.192,18.192,0,0,1-1.122,6.325l-.164-.037a16.092,16.092,0,0,0-3.256-11.734A13.839,13.839,0,0,0,33.5,16.892c-.172-.164-.36-.34-.558-.51-.142-.125-.317-.272-.5-.411a13.583,13.583,0,0,0-16.712-.059,11.5,11.5,0,0,0-2.026,16.134,9.711,9.711,0,0,0,6.5,3.706,10.094,10.094,0,0,0,1.238.076,9.664,9.664,0,0,0,5.984-2.057,8.36,8.36,0,0,0,1.485-11.72c-.091-.119-.165-.21-.235-.292a5.671,5.671,0,0,0-.53-.547,6.8,6.8,0,0,0-.538-.464,7.132,7.132,0,0,0-8.781.025,6.129,6.129,0,0,0-1.981,2.655,6,6,0,0,0,5.528,8.313c.2,0,.405-.011.618-.031a6.017,6.017,0,0,0,4.247-2.46l.147.085a5.562,5.562,0,0,1-1.7,2.2A6.872,6.872,0,0,1,21.45,33a7.259,7.259,0,0,1-.884-.057A6.891,6.891,0,0,1,15.947,30.3a8.663,8.663,0,0,1,1.527-12.15,10.738,10.738,0,0,1,13.1-.04c.159.119.332.258.513.414.147.13.286.261.425.391a10.776,10.776,0,0,1,1.015,1.128,13.236,13.236,0,0,1-2.338,18.539A16.005,16.005,0,0,1,20.45,41.98c-.063,0-.131,0-.193,0a16.258,16.258,0,0,1-11.68-4.959L8.549,37A18.2,18.2,0,0,1,6.228,13.172,18.44,18.44,0,0,1,12.6,7.6a18.23,18.23,0,0,1,8.472-2.091A18.042,18.042,0,0,1,29.338,7.5a18.485,18.485,0,0,1,6.3,5.3c.252.329.493.674.737,1.054a18.117,18.117,0,0,1,2.93,9.9M37.258,10.273,37.2,10.2A21.423,21.423,0,0,0,30.543,4.93a21.073,21.073,0,0,0-5.675-1.913l.011-.167c.289-.009.578-.014.867-.014.2,0,.4,0,.6.008a24.441,24.441,0,0,1,13.632,4.6l.079.057Z"
                          transform="translate(0 0.001)"
                          fill="#fef3e4"
                        />
                        <path
                          id="Path_10357"
                          data-name="Path 10357"
                          d="M43.106,6.289A27.1,27.1,0,0,0,26.414.008C26.207,0,26,0,25.791,0A27.163,27.163,0,0,0,9.209,5.675,22.526,22.526,0,0,0,3.89,11.56,21.035,21.035,0,0,0,6.6,39.059a18.742,18.742,0,0,0,6.327,4.3,19,19,0,0,0,7.344,1.462h.162c.2.006.417.009.641.009A21.1,21.1,0,0,0,42.149,23.752c0-.091,0-.181,0-.272s0-.15,0-.227a.017.017,0,0,0,0-.011,20.942,20.942,0,0,0-3.162-10.589l-.034-.059,4.225-4.182a1.108,1.108,0,0,0,.13-.145l.031-.043c.02-.025.045-.063.071-.1l.023-.037.037-.076c.02-.039.037-.079.054-.113l.023-.068.037-.13a1.415,1.415,0,0,0-.468-1.408m-3.8,17.463a18.192,18.192,0,0,1-1.122,6.325l-.164-.037a16.092,16.092,0,0,0-3.256-11.734A13.839,13.839,0,0,0,33.5,16.892c-.172-.164-.36-.34-.558-.51-.142-.125-.317-.272-.5-.411a13.583,13.583,0,0,0-16.712-.059,11.5,11.5,0,0,0-2.026,16.134,9.711,9.711,0,0,0,6.5,3.706,10.094,10.094,0,0,0,1.238.076,9.664,9.664,0,0,0,5.984-2.057,8.36,8.36,0,0,0,1.485-11.72c-.091-.119-.165-.21-.235-.292a5.671,5.671,0,0,0-.53-.547,6.8,6.8,0,0,0-.538-.464,7.132,7.132,0,0,0-8.781.025,6.129,6.129,0,0,0-1.981,2.655,6,6,0,0,0,5.528,8.313c.2,0,.405-.011.618-.031a6.017,6.017,0,0,0,4.247-2.46l.147.085a5.562,5.562,0,0,1-1.7,2.2A6.872,6.872,0,0,1,21.45,33a7.259,7.259,0,0,1-.884-.057A6.891,6.891,0,0,1,15.947,30.3a8.663,8.663,0,0,1,1.527-12.15,10.738,10.738,0,0,1,13.1-.04c.159.119.332.258.513.414.147.13.286.261.425.391a10.776,10.776,0,0,1,1.015,1.128,13.236,13.236,0,0,1-2.338,18.539A16.005,16.005,0,0,1,20.45,41.98c-.063,0-.131,0-.193,0a16.258,16.258,0,0,1-11.68-4.959L8.549,37A18.2,18.2,0,0,1,6.228,13.172,18.44,18.44,0,0,1,12.6,7.6a18.23,18.23,0,0,1,8.472-2.091A18.042,18.042,0,0,1,29.338,7.5a18.485,18.485,0,0,1,6.3,5.3c.252.329.493.674.737,1.054a18.117,18.117,0,0,1,2.93,9.9M37.258,10.273,37.2,10.2A21.423,21.423,0,0,0,30.543,4.93a21.073,21.073,0,0,0-5.675-1.913l.011-.167c.289-.009.578-.014.867-.014.2,0,.4,0,.6.008a24.441,24.441,0,0,1,13.632,4.6l.079.057Z"
                          transform="translate(0 0.001)"
                          fill="#fef3e4"
                        />
                      </g>
                    </svg>{" "}
                  </a>
                </div>

                <div>
                <p className="mb-0 mt-4 gilroymedium">
            Drive Success Like Never Before With
            </p>
            <h1 className="h1">
            EASYOKR
            </h1>
                </div>
              </div>
            </div>
          </div>
          {/* Div For SignUp Form */}
          {ShowOtpModal ? (
            <div className="col-lg-6 col-12 vh-100">
              <div className="d-flex justify-content-center align-items-center h-100">
                <div className="p-xxl-0 login-form-container">
                  <div className="text-center">
                    <svg
                      className="signup-icon"
                      id="Group_13318"
                      data-name="Group 13318"
                      xmlns="http://www.w3.org/2000/svg"
                      width="81.575"
                      height="81.546"
                      viewBox="0 0 81.575 81.546"
                    >
                      <path
                        id="Path_10241"
                        data-name="Path 10241"
                        d="M0,43.467V38.05c.124-1,.228-1.995.374-2.987a39.348,39.348,0,0,1,9.513-20.9C19.643,3.184,31.925-1.4,46.475.372A37.713,37.713,0,0,1,67.322,9.8C78.958,20.153,83.5,33.151,80.835,48.457c-1.769,10.157-7.084,18.352-15.222,24.675A39.1,39.1,0,0,1,47.9,80.918c-1.458.246-2.928.421-4.392.629H38.087c-.229-.051-.457-.12-.689-.15-1.443-.191-2.9-.29-4.33-.573A40.283,40.283,0,0,1,8.558,65.754,39.11,39.11,0,0,1,.629,47.86C.383,46.4.208,44.932,0,43.467m31.054,1.28a17.2,17.2,0,1,1,21.9-26.338,16.516,16.516,0,0,1,5.008,12.1,17.056,17.056,0,0,1-7.468,14.2A28.425,28.425,0,0,1,68.01,64.159c8.216-9.074,11.705-24.091,5.848-37.642a36,36,0,0,0-68.07,5.862,36.054,36.054,0,0,0,7.776,31.856,28.4,28.4,0,0,1,17.49-19.487M41.686,76.757c1.084-.11,3.132-.2,5.14-.541a35.2,35.2,0,0,0,16.6-7.531,1.1,1.1,0,0,0,.329-.842,19.446,19.446,0,0,0-1.9-6.524c-3.9-7.9-10.247-12.438-18.995-13.347A21.955,21.955,0,0,0,27.2,52.308,22.637,22.637,0,0,0,17.846,67.8a1.157,1.157,0,0,0,.345.908c6.581,5.283,14.111,7.934,23.5,8.053M53.173,30.521A12.378,12.378,0,1,0,40.818,42.868,12.453,12.453,0,0,0,53.173,30.521"
                        transform="translate(0 -0.001)"
                        fill="#1b222b"
                      />
                    </svg>
                  </div>
                  <h2 className="mb-2 text-center gilroysemibold fw-bold letter-spacing">
                    Sign Up
                  </h2>
                  <div className="text-center mb-4">
                    <img src={devider} alt="" className="devider-line-icon" />
                  </div>
                  <form onSubmit={handleSubmit}>
                    <div className="row">
                      <div className="mb-3">
                        <label
                          htmlFor="firstName"
                          className="form-label gilroyregular fw-600"
                        >
                          <span className="text-danger">*</span> First name
                        </label>
                        <input
                          type="text"
                          autoComplete="off"
                          name="firstName"
                          id="firstName"
                          placeholder="Enter your first name"
                          maxLength={15}
                          value={values?.firstName}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          className="form-control gilroyregular"
                          //aria-describedby="emailHelp"
                        />
                        {errors?.firstName && touched?.firstName ? (
                          <p className="text-danger font-13 pt-1 mb-0">
                            {" "}
                            {errors.firstName}
                          </p>
                        ) : null}
                      </div>
                    </div>
                    <div className="mb-3">
                      <label
                        htmlFor="lastName"
                        className="form-label gilroyregular fw-600"
                      >
                        <span className="text-danger">*</span> Last name
                      </label>
                      <input
                        type="text"
                        autoComplete="off"
                        name="lastName"
                        id="lastName"
                        placeholder="Enter your last name"
                        maxLength={15}
                        value={values?.lastName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        className="form-control gilroyregular"
                        //aria-describedby="emailHelp"
                      />
                      {errors?.lastName && touched?.lastName ? (
                        <p className="text-danger font-13 pt-1">
                          {errors.lastName}
                        </p>
                      ) : null}
                    </div>
                    <div className="mb-3">
                      <label
                        htmlFor="email"
                        className="form-label gilroyregular fw-600"
                      >
                        <span className="text-danger">*</span> Email Id
                      </label>
                      <input
                        type="email"
                        name="email"
                        id="email"
                        autoComplete="off"
                        value={values?.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Enter your email Id"
                        className="form-control gilroyregular"
                        aria-describedby="emailHelp"
                      />
                      {errors?.email && touched?.email ? (
                        <p className="text-danger  font-13 pt-1">
                          {errors.email}
                        </p>
                      ) : null}
                    </div>
                    {errorMessageData && errorMessageData ? (
                      <p className="text-danger ">{errorMessageData}</p>
                    ) : null}
                    <button
                      type="submit"
                      className="btn sign-up-button-color text-light w-100 fw-600 gilroysemibold mt-4"
                    >
                      Sign Up
                    </button>
                    <div className="text-center mt-3">
                      <span className="gilroyregular fw-600">
                        Already have an account?{" "}
                        <NavLink to="login">Login</NavLink>
                      </span>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          ) : (
            <VerifyOtp SignUpResponseData={SignUpResponseData} />
          )}
        </div>
      </div>
    </div>
  );
}
