/* eslint-disable */
// eslint-disable-next-line react/no-deprecated
import React, { useEffect, useState } from "react";
import AppLayout from "../Layout/AppLayout";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import OwlCarousel from "react-owl-carousel";
import departmentImg from "../../assets/images/Icons/SVG/Group 22974.svg";
import "react-circular-progressbar/dist/styles.css";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import BarChart from "./BarChart";
import { useSelector } from "react-redux";
import API from "../../Api/Api";
import Carousel from "react-grid-carousel";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { useNavigate } from "react-router-dom";

export default function Dashboard() {
  const [isOwlCarouselVisible, setIsOwlCarouselVisible] = useState(false);
  const [DashboardData, setdashboardData] = useState(null);
  const { sDate, EDate } = useSelector((state) => state.date);
  console.log(EDate, sDate, "Dates");
  const UsersData = JSON.parse(localStorage.getItem("DepartmentUser"));
  const navigate = useNavigate();
  const getAllDashboardData = () => {
    if (sDate && EDate) {
      API.Dashboard.getDashboardData({ sDate, EDate })
        .then((response) => {
          if (response.data.statusCode === 200) {
            setdashboardData(response.data.data);
            setIsOwlCarouselVisible(true);
          }
        })
        .catch((err) => {
          console.log(err);
          if(err.response.status===403){
            navigate("/");
            }
        });
    }
  };
  useEffect(() => {
    getAllDashboardData();
  }, [sDate, EDate]);

  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    // Initialize OwlCarousel only once
    if (!initialized) {
      // Add your OwlCarousel initialization code here
      $(".owl-carousel").owlCarousel();
      setInitialized(true);
    }
  }, [initialized]);

  const Link = ({ id, children, title }) => (
    <OverlayTrigger
      overlay={
        <Tooltip id={id}>
          <div
            className="tooltip-inner-custom"
            style={{
              backgroundColor: "#f7f9ff",
              color: "#000",
              padding: "10px",
              borderColor: "#f7f9ff",
            }}
          >
            {title}
          </div>
        </Tooltip>
      }
      trigger={title.length > 30 ? ["hover", "focus"] : []}
    >
      <span>{children}</span>
    </OverlayTrigger>
  );
  const TotalOKRper =
    (DashboardData?.org_objective_list?.overall_obj_score * 100) / 100;
  return (
    <>
      <AppLayout>
        <div className="main-content mt-4">
          <div className="page-content mb-5">
            <div className="row welcomeHeading">
              <div className="col-12">
                <h3 className="mb-0 fw-bold">
                  Welcome to {UsersData?.dept_name} Dashboard
                </h3>
              </div>
            </div>
            <div className="row mt-4">
              <div className="col-12">
                <h4 className="">Organizational Objective</h4>
              </div>
            </div>
            <div className="row bg-light-blue p-3">
              <div className="col-xl-2 col-lg-3 d-flex justify-content-center">
                <div>
                  <p className="textBlueLight1 fw-bold">Overall OKR Growth</p>
                  <>
                    <div
                      style={{ width: "150px", height: "150px" }}
                      className="m-3"
                    >
                      <CircularProgressbar
                        // key={animationKey}
                        value={
                          DashboardData?.org_objective_list?.overall_obj_score
                        }
                        text={`${
                          Math.round(
                            DashboardData?.org_objective_list
                              ?.overall_obj_score === undefined
                              ? 0
                              : DashboardData?.org_objective_list
                                  ?.overall_obj_score * 100
                          ) / 100
                        }%`}
                        strokeWidth={10}
                        styles={buildStyles({
                          rotation: 0.0,
                          strokeLinecap: "butt",
                          textSize: "20px",
                          className: "bold-text",
                          pathTransitionDuration: 0.5,
                          pathColor: `${
                            TotalOKRper < 30
                              ? "red"
                              : TotalOKRper > 30 && TotalOKRper < 60
                              ? "#ECB159"
                              : "green"
                          }`,
                          textColor: `${
                            TotalOKRper < 30
                              ? "red"
                              : TotalOKRper > 30 && TotalOKRper < 60
                              ? "#ECB159"
                              : "green"
                          }`,
                          trailColor: "#d6d6d6",
                          backgroundColor: "#3e98c7",
                        })}
                      />
                    </div>
                  </>
                </div>
              </div>
              <div className="col-lg-9 col-12 ms-3">
                {/* <div className="row"> */}
                {isOwlCarouselVisible && (
                  <OwlCarousel
                    items={4}
                    slideBy={4}
                    className="owl-theme ms-5 dashboardSlider"
                    dots={false}
                    nav
                    margin={8}
                    responsive={{
                      0: { items: 1 },
                      768: { items: 3 },
                      992: { items: 4 },
                    }}
                  >
                    {DashboardData &&
                      DashboardData?.org_objective_list &&
                      DashboardData?.org_objective_list?.result.map(
                        (item, index) => {
                          return (
                            <div
                              className="col-xl-3 col-md-6 d-flex justify-content-center ps-5"
                              key={index}
                            >
                              <div
                                className="p-3 progressCard mb-3"
                                style={{
                                  height: "228px",
                                  wordBreak: "break-all",
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <Link
                                  title={item?.objective_name}
                                  id="t-1"
                                  className="textBlueLight1 fw-bold mb-0"
                                >
                                  {item?.objective_name.length > 30
                                    ? item?.objective_name.slice(0, 30) + "..."
                                    : item?.objective_name}{" "}
                                </Link>

                                <div className="d-flex justify-content-center align-items-center mt-auto mx-4 mb-4">
                                  <div
                                    style={{ width: "100px", height: "100px" }}
                                  >
                                    <CircularProgressbar
                                      value={item?.org_percentage_calculation}
                                      text={`${
                                        Math.round(
                                          item?.org_percentage_calculation * 100
                                        ) / 100
                                      }%`}
                                      strokeWidth={10}
                                      styles={buildStyles({
                                        rotation: 0.0,
                                        strokeLinecap: "butt",
                                        textSize: "20px",
                                        className: "bold-text",
                                        pathTransitionDuration: 0.5,
                                        pathColor: `
                                        ${
                                          item?.org_percentage_calculation < 30
                                            ? "red"
                                            : item?.org_percentage_calculation >
                                                30 &&
                                              item?.org_percentage_calculation <
                                                60
                                            ? "#ECB159"
                                            : "green"
                                        }
                                       `,
                                        textColor: `${
                                          item?.org_percentage_calculation < 30
                                            ? "red"
                                            : item?.org_percentage_calculation >
                                                30 &&
                                              item?.org_percentage_calculation <
                                                60
                                            ? "#ECB159"
                                            : "green"
                                        }
                                       `,
                                        trailColor: "#d6d6d6",
                                        backgroundColor: "#3e98c7",
                                      })}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        }
                      )}
                  </OwlCarousel>
                )}
                {/* </div> */}
              </div>
            </div>
            <div className="row">
              <div className="col-xl-5">
                <h4 className="mt-4">Organization Objective Progress</h4>
                <BarChart
                  data={DashboardData && DashboardData?.org_objective_progress}
                />
              </div>
              <div className="col-xl-7">
                <div className="row justify-content-center">
                  <div className="col-lg-11">
                    <h4 className="mt-4">Departmental OKR Growth</h4>
                    <div>
                      {isOwlCarouselVisible && (
                        <OwlCarousel
                          items={1}
                          className="owl-theme"
                          dots={false}
                          nav
                          margin={8}
                        >
                          {DashboardData &&
                            DashboardData?.dept_obj_progress &&
                            DashboardData?.dept_obj_progress.map(
                              (item, index) => {
                                return (
                                  <div key={index}>
                                    <div className="row departmentHeading mt-2">
                                      <div className="col-12 d-flex justify-content-between align-items-center">
                                        <div className="d-flex align-items-center">
                                          {/* <img
                                            src={
                                              item?.profileImageUrl
                                                ? `${process.env.REACT_APP_BASE_URL}/images/${item?.profileImageUrl}`
                                                : departmentImg
                                            }
                                            className="me-3 swiper-slide-img"
                                          /> */}
                                          <div>
                                            <h4 className="textBlueLight1 mb-0 fw-bold ms-2">
                                              {item?.dept_objective_name
                                                ? item?.dept_objective_name
                                                : "-"}
                                            </h4>
                                            {/* <p className="lightGrey mb-0">
                                              {item?.fullName
                                                ? item?.fullName
                                                : "-"}
                                            </p> */}
                                          </div>
                                        </div>
                                        <h3 className="textBlueLight1 fw-bold me-2">
                                          {item?.department_objective_average
                                            ? Math.round(
                                                item?.department_objective_average *
                                                  100
                                              ) / 100
                                            : 0}
                                          %
                                        </h3>
                                      </div>
                                    </div>
                                    <div className="row departmentCards p-3">
                                      {item.key_result?.length > 3 ? (
                                        <Carousel
                                          cols={3}
                                          rows={1}
                                          gap={10}
                                          loop
                                          showArrows={true}
                                        >
                                          {item?.key_result.map(
                                            (item, index) => (
                                              <Carousel.Item
                                                key={index}
                                                className="mx-3"
                                              >
                                                {/* <div className="col-md-3 col-sm-6"> */}
                                                <div
                                                  className="progressCard p-3 text-center mb-3 d-flex align-items-center justify-content-center"
                                                  style={{ height: "140px" }}
                                                >
                                                  <div>
                                                    {/* <p className="lightGrey mb-2">
                                                  {
                                                    item?.objective_initiative_task
                                                  }
                                                </p> */}
                                                    <Link
                                                      title={
                                                        item?.objective_initiative_task
                                                      }
                                                      id="t-1"
                                                      className="textBlueLight1 fw-bold mb-0"
                                                    >
                                                      {item
                                                        ?.objective_initiative_task
                                                        .length > 30
                                                        ? item?.objective_initiative_task.slice(
                                                            0,
                                                            30
                                                          ) + "..."
                                                        : item?.objective_initiative_task}{" "}
                                                    </Link>

                                                    <h4 className="textBlueLight1 fw-bold me-2 mt-4">
                                                      {Math.round(
                                                        item?.current_score *
                                                          100
                                                      ) / 100}
                                                      %
                                                    </h4>
                                                  </div>
                                                </div>
                                                {/* </div> */}
                                              </Carousel.Item>
                                            )
                                          )}
                                        </Carousel>
                                      ) : (
                                        <>
                                          <div className="row">
                                            {item?.key_result.map(
                                              (item, index) => (
                                                <div
                                                  className="col-4"
                                                  key={index}
                                                >
                                                  <div
                                                    className="progressCard p-3 text-center mb-3 d-flex align-items-center justify-content-center"
                                                    style={{ height: "140px" }}
                                                  >
                                                    <div>
                                                      {/* <p className="lightGrey mb-2">
                                          {
                                            item?.objective_initiative_task
                                          }
                                        </p> */}
                                                      <Link
                                                        title={
                                                          item?.objective_initiative_task
                                                        }
                                                        id="t-1"
                                                        className="textBlueLight1 fw-bold mb-0"
                                                      >
                                                        {item
                                                          ?.objective_initiative_task
                                                          .length > 30
                                                          ? item?.objective_initiative_task.slice(
                                                              0,
                                                              30
                                                            ) + "..."
                                                          : item?.objective_initiative_task}{" "}
                                                      </Link>

                                                      <h4 className="textBlueLight1 fw-bold me-2">
                                                        {Math.round(
                                                          item?.current_score *
                                                            100
                                                        ) / 100}
                                                        %
                                                      </h4>
                                                    </div>
                                                  </div>
                                                </div>
                                              )
                                            )}
                                          </div>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                );
                              }
                            )}
                        </OwlCarousel>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </AppLayout>
    </>
  );
}
