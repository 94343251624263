/* eslint-disable */

import React, { useEffect, useState } from "react";
import setObjective from "../../../assets/images/Icons/Pics/Set Objective.png";
import editIcon from "../../../assets/images/Icons/SVG/Edit Icon.svg";
import deleteIcon from "../../../assets/images/Icons/SVG/Delete Icon.svg";
import timelyDelivery from "../../../assets/images/Icons/Pics/Department/SLA.png";
import API from "../../../Api/Api";
import { useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const DefineDepartment = (props) => {
  const [subObjectiveList, setSubObjectiveList] = useState(null);
  const [KeyResultList, setKeyResultList] = useState(null);
  const [Editid, setEditID] = useState(null);
  const [isEditing, setEditing] = useState(false);
  
  const [deleteId, setDeleteId] = useState();

  const navigate = useNavigate();
  console.log(KeyResultList);
  const validate = (values) => {
    console.log(values, "value");
    const spaceReg = /^\S.*\S$/;
    const paragraphReg=/^[^\s]+(\s+[^\s]+)*$/

    const errors = {};
    if (!values.objective_initiative_task) {
      errors.objective_initiative_task = "Please enter department task";
    }else if (!paragraphReg.test(values.objective_initiative_task)) {
      errors.objective_initiative_task = "Cannot start & end with a space";
    }
    if (!values.key_result) {
      errors.key_result = "Please enter department key result";
    }else if (!spaceReg.test(values.key_result)) {
      errors.key_result = "Cannot start & end with a space";
    }
    if (!values.department_objective_id) {
      errors.department_objective_id = "Please select department objective";
    }

    console.log("Erroes", errors);
    return errors;
  };
  const formik = useFormik({
    initialValues: {
      objective_initiative_task: "",
      key_result: "",
      department_objective_id: "",
    },
    onSubmit: (values) => {
      console.log(values, "hello values");
      if (Editid) {
        handleUpdate();
      } else {
      createKeyResult();
      }
    },
    validate,
  });

  const getDepartmentList = () => {
    API.Auth.OrgObjDepartmentList()
      .then((response) => {
        if (response.data.statusCode === 200) {
          console.log(response);
          setSubObjectiveList(response.data.data);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    getDepartmentList();
    getAllKeyResultList();
  }, []);

  const createKeyResult = () => {
    API.Auth.RegisterAPI({
      data: {
        department_objective_id: formik.values.department_objective_id,
        key_result: formik.values.key_result,
        objective_initiative_task: formik.values.objective_initiative_task,
        flag: 4,
      },
    }).then((response) => {
      if (response.data.statusCode === 200) {
        console.log(response);
        formik.resetForm();
        getAllKeyResultList();
      }else{
        toast.error(response?.data?.message);

      }
    });
  };

  const getAllKeyResultList = () => {
    API.Auth.GetALlKeyResultAPI()
      .then((response) => {
        if (response.data.statusCode === 200) {
          setKeyResultList(response.data.data);

          console.log(response);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleEdit = (id) => {
    const objectiveToEdit = KeyResultList.find((item) => item._id === id);
    console.log(objectiveToEdit);
    if (objectiveToEdit) {
      formik.setValues({
        key_result: objectiveToEdit.key_result,
        objective_initiative_task: objectiveToEdit.objective_initiative_task,
        department_objective_id: objectiveToEdit.department_objective_id,
      });
      setEditing(true);
      setEditID(id);
    }
  };
  const handleNextButton = () => {
    props.handleNext();

    navigate(`/dashboard`);
  };

  // Update api
  const handleUpdate = () => {
    const objectiveToEdit = KeyResultList.find((item) => item._id === Editid);

    if (
      objectiveToEdit.key_result === formik.values.key_result &&
      objectiveToEdit.objective_initiative_task === formik.values.objective_initiative_task &&
      objectiveToEdit.department_objective_id === formik.values.department_objective_id
    ) {
      formik.resetForm();
      setEditing(false);
      setEditID("");
    } else {
      API.Auth.UpdateKeyResultAPI({
        data: {
          _id: Editid,
          department_objective_id: formik.values.department_objective_id,
          key_result: formik.values.key_result,
          objective_initiative_task: formik.values.objective_initiative_task,
        },
      }).then((response) => {
        if (response?.data?.statusCode === 200) {
        console.log("response", response.data);
        getAllKeyResultList();
        formik.resetForm();
        setEditing(false);
        setEditID("");
        console.log("Objective Successfully Updated");
      } else {
        // Handle error or provide feedback to the user
        toast.error(response?.data?.message);
      }
      }).catch (error=>{
      console.log(error);
    })
  }
  };

  const handleDelete = (id) => {
    setDeleteId(id);
  };

  // Deelete API
  const deleteDepartment = () => {
    try {
      API.Auth.DeleteKeyResultAPI({
        data: { _id: deleteId },
      }).then((response) => {
        if(response.data.statusCode ===200){
        const newObjectiveId = response.data.data._id;
        const updatedArray = KeyResultList.filter(
          (item) => item._id !== newObjectiveId
        );
        setKeyResultList(updatedArray);
        document.querySelector("#DeleteDepartemnt").click();
        formik.resetForm();
        setEditing(false);
        setEditID("");
        console.log("Objective Successfully Deleted");
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
  const handleCancel = (event) => {
    event.preventDefault();
    formik.resetForm();
    setEditing(false);
  };
  return (
    <div className="mt-4">
      <div className="row justify-content-center mt-4">
        <div className="col-xxl-11 col-12">
          <h2 className="textBlue fw-normal">Set Key Result</h2>
          <p className="textGrey">
            Make sure your key results are to the point{" "}
          </p>
          <div className="row">
            <div className="col-xl-6 pe-xl-5 pb-5 border-right">
              <p className="textBlueLight">
                Select organizational objective and set your objective
              </p>

              <form>
                <div className="mb-4">
                  <div className="form-group">
                    <select
                      className="form-select form-control"
                      aria-label="Default select example"
                      id="definedepartment"
                      name="department_objective_id"
                      value={formik.values.department_objective_id}
                      onChange={formik.handleChange}
                    >
                      <option selected>Select departmental objective</option>
                      {subObjectiveList &&
                        subObjectiveList.map((item) => {
                          return (
                            <option value={item?._id} key={item?._id}>
                              {item?.dept_objective_name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                  {formik.errors.department_objective_id &&
                  formik.touched.department_objective_id ? (
                    <div className="text-danger">
                      {formik.errors.department_objective_id}
                    </div>
                  ) : null}
                </div>
                <div className="mb-4">
                  <div className="form-group">
                    <textarea
                      className="form-control"
                      placeholder="
                      Mention Initiative towards the objective"
                      id="floatingTextarea"
                      rows="4"
                      maxLength="100"
                      name="objective_initiative_task"
                      value={formik.values.objective_initiative_task}
                      onChange={formik.handleChange}
                    ></textarea>
                  </div>
                  {formik.errors.objective_initiative_task &&
                  formik.touched.objective_initiative_task ? (
                    <div className="text-danger">
                      {formik.errors.objective_initiative_task}
                    </div>
                  ) : null}
                </div>
                <div className="mb-4">
                  <div className="form-group">
                    <input
                      type="text"
                      className="form-control mt-2"
                      placeholder="Enter Key Result"
                      id="exampleInputfirstname"
                      name="key_result"
                      value={formik.values.key_result}
                      onChange={formik.handleChange}
                    />
                  </div>
                  {formik.errors.key_result && formik.touched.key_result ? (
                    <div className="text-danger">
                      {formik.errors.key_result}
                    </div>
                  ) : null}              
                </div>

                {!isEditing ? (
                  <div className="text-center">
                    <button
                      type="submit"
                      className="btn set-objective-button w-100"
                      onClick={formik.handleSubmit}
                    >
                      Publish
                    </button>
                  </div>
                ) : (
                  <div className="d-flex justify-content-between">
                    <button
                      type="submit"
                      className="btn set-objective-button px-5"
                      onClick={(event) => handleCancel(event)}
                    >
                      Cancel
                    </button>
                    <button
                      type="submit"
                      className="btn sign-up-button-color text-white px-5"
                      onClick={formik.handleSubmit}
                    >
                      Update
                    </button>
                  </div>
                )}
              </form>
            </div>
            <div className="col-xl-6 ps-xl-5">
              <p className="textBlueLight">
                Here are the list of your departments and their departmental
                leaders
              </p>
              {KeyResultList?.length == 0 ? (
                <div className="row">
                  <div className="col-12 text-center py-5">
                    <img src={setObjective} />
                  </div>
                </div>
              ) : (
                <>
                  {KeyResultList &&
                    KeyResultList.map((item, index) => {
                      return (
                        <div
                          className="row d-flex align-items-center w-100 mb-1"
                          key={index}
                        >
                          <div className="col-10">
                            <div className="setObjectiveListBox p-3">
                              <div className="d-flex">
                                <img
                                  src={timelyDelivery}
                                  className="Width-20 me-2"
                                />
                                <div className="setObjectiveListBox">
                                  <p className="mb-1 textBlueLight1" style={{overflowWrap: 'anywhere'}}>
                                    {item?.dept_objective_name}
                                  </p>
                                </div>
                              </div>
                              <div className="d-flex">
                                <i className="fa-solid fa-arrow-right me-2 iconBlue"></i>
                                <div className="setObjectiveListBox">
                                  <p className="mb-1 textLightGrey" style={{overflowWrap: 'anywhere'}}>
                                    {item?.objective_initiative_task}
                                  </p>
                                </div>
                              </div>
                              <div className="d-flex">
                                <i className="fa-solid fa-key me-2 iconBlue"></i>
                                <div className="setObjectiveListBox">
                                  <p className="mb-1 textGrey" style={{overflowWrap: 'anywhere'}}>
                                    {item?.key_result}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-2">
                            <div className="d-flex">
                              <img
                                src={editIcon}
                                onClick={() => handleEdit(item?._id)}
                              />
                              <button
                              type="button"
                              className="btn"
                              data-bs-toggle="modal"
                              id="DeleteDepartemnt"
                              data-bs-target="#staticBackdrop1"
                              onClick={() => handleDelete(item._id)}
                            >
                              <img src={deleteIcon} />
                            </button>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </>
              )}
              <button
                className={`btn ${
                  KeyResultList?.length === 0
                    ? "sign-up-button-color-onempty"
                    : "sign-up-button-color"
                } text-light w-100 mt-3`}
                onClick={() => handleNextButton()}
                disabled={KeyResultList?.length === 0}
              >
                Finish
              </button>
            </div>
          </div>
        </div>
      </div>
       {/* delete modal
       */}
      <div
        className="modal fade"
        id="staticBackdrop1"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
        // tabIndex={-1}
        aria-labelledby="staticBackdropLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered bg-transparent selfModelWidth deleteModalC">
          <div className="modal-content border-0 p-5">
            <div className="modal-body text-start text-center">
              <h2 className="textGrey">
                Are you sure you want to delete this?
              </h2>
              <div className="d-flex justify-content-center mt-3">
                <button
                  className="btn btnOrganization w-auto px-5 mt-3 me-3 "
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Cancel
                </button>
                <button
                  className="btn sign-up-button-color text-white px-5 mt-3"
                  onClick={() => deleteDepartment()}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DefineDepartment;
