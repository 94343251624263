import React, { useEffect, useState } from "react";
import AppLayout from "../Layout/AppLayout";
import { NavLink, useParams,useNavigate } from "react-router-dom";
import departmentImg from "../../assets/images/Icons/SVG/Group 22974.svg";
import IncreaseBusinessIcon from "../../assets/images/Icons/SVG/Selected Org.svg";
import checkListIcon from "../../assets/images/Icons/Pics/Department/SLA.png";
import API from "../../Api/Api";
import { useFormik } from "formik";
// import Select from "react-select";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";
import useTaskScoreAverage from "../comman/KeyResultScoreLogic";
export default function TimelyDelivery() {
  const [keyResultList, setKeyResultList] = useState(null);
  const [avrageCal, setAvrageCall] = useState("");

  // const [selectedOption, setSelectedOption] = useState(null);
  const postData = useSelector((state) => state.date.departmentData);
  const deptInfo = useSelector((state) => state.date.objectiveInfo);
  const navigate=useNavigate();

  // const [ownerList, setOwnerList] = useState(null);
  // const [keyResultId, setkeyResultId] = useState(null);
  const { id } = useParams();
  const { type } = useParams();
  const { getTaskScoreAverage } = useTaskScoreAverage();
  const getGetResultList = () => {
    API.Department.getDepartmentKeyResultList(id)
      .then((response) => {
        if (response.data.statusCode === 200) {
          console.log(response);
          setKeyResultList(response.data.data);
          // getTaskScoreAverage(response.data.data);
          const average =  getTaskScoreAverage(response.data.data);
          setAvrageCall(average);
        }
      })
      .catch((err) => {
        if(err.response.status===403){
          navigate("/");
          }
        console.log(err);
      });
  };
  useEffect(() => {
    getGetResultList();
    // getOwnerList();
  }, []);
  const indexToLetter = (index) => String.fromCharCode(97 + index);
  const [editableIndex, setEditableIndex] = useState(null);
  const [currentScore, setCurrentScore] = useState(0);
  const handleFieldClick = (index) => {
    setEditableIndex(index);
    setCurrentScore(
      keyResultList[index]?.current_score || 0
    );
  };

  const handleInputChange = (event) => {
    // Extract the numeric part of the input value
    const numericValue = event.target.value.replace(/\D/g, '');

    // Ensure the numeric value is not greater than 100
    if (numericValue && parseInt(numericValue, 10) > 100) {
      setCurrentScore('100');
    } else {
      setCurrentScore(numericValue);
    }
  };

  const handleSave = (id) => {
    API.Department.UpdateCurrentScore({
      data: {
        _id: id,
        score: currentScore,
      },
    })
      .then((response) => {
        if (response.data.statusCode === 200) {
          console.log(response.data.data);
          getGetResultList();
          setCurrentScore("");
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // Handle save logic, update the data, or make an API call

    // Reset editable state
    setEditableIndex(null);
  };

  const validate = (values) => {
    // const regex = /[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$/;
    const spaceReg = /^\S.*\S$/;
    const paragraphReg=/^[^\s]+(\s+[^\s]+)*$/

    const errors = {};
    if (!values.objective_initiative_task) {
      errors.objective_initiative_task = "Please enter department task";
    } else if (!paragraphReg.test(values.objective_initiative_task)) {
      errors.objective_initiative_task = "Cannot start & end with a space";
    }
    if (!values.key_result) {
      errors.key_result = "Please enter department key result";
    } else if (!spaceReg.test(values.key_result)) {
      errors.key_result = "Cannot start & end with a space";
    }
    // if (!values.department_objective_id) {
    //   errors.department_objective_id = "Please select department objective";
    // }
    // if (values.owner_dependencies.length === 0) {
    //   errors.owner_dependencies = "Please select department owner";
    // }
    console.log("Erroes", errors);
    return errors;
  };
  const formik = useFormik({
    initialValues: {
      department_objective_id: "",
      key_result: "",
      objective_initiative_task: "",
      owner_dependencies: [],
    },
    onSubmit: () => {
      formik.resetForm();
      createTaskAPI();
    },
    validate,
  });

  // const getOwnerList = () => {
  //   API.Department.getDepartmentOwners()
  //     .then((response) => {
  //       if (response.data.statusCode === 200) {
  //         console.log(response.data.data);
  //         setOwnerList(response.data.data);
  //       }
  //     })
  //     .catch((err) => {
  //       if(err.response.status===403){
  //         navigate("/");
  //         }
  //       console.log(err);
  //     });
  // };
  // console.log(selectedOption, "selectedOption");
  // const options = ownerList?.map((owner) => ({
  //   label: (
  //     <div className="custom-option">
  //       <div className="row">
        
  //         <div className="col-3 userName d-flex align-items-center ps-4">
  //           <strong>
  //             {owner?.fullName ? owner?.fullName :"-"}
            
  //           </strong>
  //         </div>
  //         <div className="col-8 deptName d-flex align-items-center pt-3">
  //           <p>Department : {owner.dept_name}</p>
  //         </div>
  //       </div>
  //     </div>
  //   ),
  //   value: owner._id,
  // }));

  const createTaskAPI = () => {
    API.Department.createTaskDepartment({
      data: {
        department_objective_id: id,
        key_result: formik.values.key_result,
        objective_initiative_task: formik.values.objective_initiative_task,
        owner_dependencies: formik.values.owner_dependencies,
      },
    })
      .then((response) => {
        if (response.data.statusCode === 200) {
          console.log(response.data.data);
          formik.resetForm();
          formik.setValues({
            owner_dependencies: [],
          });
          document.querySelector("#addTaskButton").click();

          getGetResultList();
        } else {
          toast.error(response?.data?.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const getIDofKeyResult = (id) => {
  //   setkeyResultId(id);
  // };
  // Add owner api
  // const updateOwner = () => {
  //   if (formik.values.owner_dependencies.length != 0) {
  //     try {
  //       API.Auth.UpdateKeyResultAPI({
  //         data: {
  //           _id: keyResultId,
  //           owner_dependencies: formik.values.owner_dependencies,
  //         },
  //       }).then((response) => {
  //         if(response.data.statusCode===200){          
  //         console.log("response", response.data);
  //         getGetResultList();
  //         formik.resetForm();
  //         formik.setValues({
  //           owner_dependencies: [],
  //         });
  //         // setEditing(false);
  //         console.log("Objective Successfully Updated");
  //         }
  //       });
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   } else {
  //     document.querySelector("#addOwnerBTn").click();
  //   }
  // };

  return (
    <AppLayout>
      <div className="main-content mt-3">
        <div className="page-content mb-5">
          <div className="row welcomeHeading">
            <div className="col-12 d-sm-flex justify-content-between">
              <div className="d-flex align-items-center ps-3 mb-lg-0 mb-3">
                <NavLink className="" to="/department">
                  <div className="backArrow me-2">
                    <i className="fa fa-solid fa-chevron-left textDark"></i>
                  </div>
                </NavLink>
                <h3 className="mb-0 fw-bold">{postData.DeptName}</h3>
              </div>
              <div className="d-flex align-items-center me-2">
                <img
                  src={
                    postData?.DeptImage
                      ? `${process.env.REACT_APP_BASE_URL}/images/${postData?.DeptImage}`
                      : departmentImg
                  }
                  className="me-3  Organization-img"
                />{" "}
                <div>
                  <small className="lightGrey mb-0">
                    {" "}
                    {postData?.DeptName} Owner
                  </small>
                  <h5 className="textDarkBlack mb-1 fw-bold">
                    {postData.fullname ? postData.fullname : "-"}
                  </h5>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-3">
            <div className="col-xl-12">
              <h3 className="textDarkBlack mb-1 fw-bold">
                Departmental Objective
              </h3>
              <small className="textDarkGrey">
                Below are the list of your key result along with the
                departmental objective
              </small>
              <div className="row mt-4">
                <div className="col-12">
                  <div className="d-sm-flex align-items-center">
                    <NavLink
                      className="text-decoration-none "
                      to={`/department-details/${postData.id}/${type}`}
                    >
                      <h4 className="mb-0 fw-bold textBlueLight1 mb-sm-0 mb-3">
                        <img src={IncreaseBusinessIcon} className="me-2" />
                        {deptInfo?.objName}
                      </h4>
                    </NavLink>
                    <div className="backArrow ms-3 d-sm-block d-none">
                      <i className="fa fa-solid fa-chevron-right textBlack"></i>
                    </div>

                    <h4 className="mb-0 fw-bold textBlueLight">
                      <img src={checkListIcon} className="me-2" />
                      Objective {deptInfo?.index + 1}
                    </h4>
                  </div>
                </div>
              </div>
              <div className="row align-items-center progressCard p-sm-3 p-2 mt-4">
                <div className="col-sm-10 mb-3 mb-sm-0">
                  <h4 className="textBlueLight">{deptInfo?.KeyResult}</h4>
                  <p className="textDarkBlack mb-0">{deptInfo?.description}</p>
                </div>
                <div className="col-sm-2">
                  <h3 className="textBlueLight fw-bold mb-0 ms-4">
                  {Math.round(avrageCal*100)/100}%
                  </h3>
                </div>
              </div>

              <div className="position-relative">
                {keyResultList &&
                  keyResultList.map((item, index) => {
                    console.log(item?.KeyResultScore[0]?.score, "score");
                    return (
                      <div
                        className="row d-flex align-items-center justify-content-center mt-3"
                        key={index}
                      >
                        <div className="col-lg-1 d-flex justify-content-center">
                          <div className="organizationNo">
                            {" "}
                            {indexToLetter(index)}
                          </div>
                          <div className="vLine"></div>
                        </div>
                        <div className="col-lg-11 bg-light-blue ">
                          <div className="row p-3 align-items-center">
                            <div className="col-12">
                              <p className="textGrey">
                                <span className="textLightGrey">Task:</span>{" "}
                                {item?.objective_initiative_task}
                              </p>
                              <div className="row p-3 progressCard shadow-none ">
                                <div className="col-lg-5 col-sm-6 col-12 mb-3">
                                  <p className="textGrey mb-1">Key Result</p>
                                  <p className="textDarkBlack mb-0">
                                    {item?.key_result}
                                  </p>
                                </div>
                                {/* <div className="col-lg-2 col-sm-6 col-12 mb-3"> */}
                                  {/* <p className="textGrey mb-1 ps-4">Owner</p>
                                  {item?.owner_department?.length != 0 ? (
                                    <div className="d-flex">
                                      {item?.owner_department.map(
                                        (item, index) => {
                                          console.log(item, "porkfnfjhf");
                                          return (
                                            <div key={index}>
                                            
                                              <img
                                                src={
                                                  item?.profileImageUrl
                                                    ? item?.profileImageUrl
                                                    : departmentImg
                                                }
                                                className="ownerImg swiper-slide-img"
                                              />
                                            </div>
                                          );
                                        }
                                      )}{" "}
                                    </div>
                                  )
                                   : (
                                    <button
                                      className="bg-light-blue py-2 textBlueLight d-inline mt-1 currentScoreInput"
                                      data-bs-toggle="modal"
                                      data-bs-target="#staticBackdrop1"
                                      id="addOwnerBTn"
                                      onClick={() =>
                                        getIDofKeyResult(item?._id)
                                      }
                                    >
                                      + Owner
                                    </button>
                                  )} */}
                                {/* </div> */}

                                <div className="col-lg-5 col-sm-6 col-12 mb-3">
                                  <p className="textGrey">
                                    Last Two Week Score
                                  </p>
                                  <div className="d-flex align-items-center">
                                    <h3 className="bg-light-blue px-3 py-2 textBlueLight1 mb-0">
                                      {item?.second_last_week_score}
                                    </h3>
                                    <div className="scoreLine mx-2"></div>
                                    <h3 className="bg-light-blue px-3 py-2 textBlueLight1 mb-0">
                                      {item?.last_week_score}
                                    </h3>
                                  </div>
                                </div>
                                <div className="col-lg-2 col-sm-6 col-12">
                                  <p className="textGrey">Current Score</p>
                                  <input
                                    className={`bg-light-blue px-4 py-2 textBlueLight d-inline mt-1 currentScoreInput ${
                                      editableIndex === index ? "editable" : ""
                                    }`}
                                    type="text"
                                    value={
                                      editableIndex === index
                                        ? `${currentScore}`
                                        : `${item?.current_score===null ?0:item?.current_score}%`
                                      // : `${item?.KeyResultScore[0]?.score}%`
                                    }
                                    readOnly={editableIndex !== index}
                                    onClick={() => handleFieldClick(index)}
                                    onChange={(e) =>
                                      handleInputChange(e)
                                    }
                                  />
                                  <i
                                    className="fa-solid fa-check ms-2 fs-4"
                                    style={{ color: "#28af28" }}
                                    onClick={() =>
                                      handleSave(
                                        item?.KeyResultScore[
                                          item?.KeyResultScore.length - 1
                                        ]?._id
                                      )
                                    }
                                  ></i>
                                  {/* <h3  className="bg-light-blue px-4 py-2 textBlueLight d-inline mt-1 currentScoreInput">
                             {item?.current_score}
                             </h3> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                {type === "user" ? (
                  <div className="row d-flex align-items-center justify-content-center mt-3">
                    <div className="col-lg-1 d-flex justify-content-center">
                      <div className="organizationNo addPlus">+</div>
                      {/* <div className="vLine"></div> */}
                    </div>
                    <div className="col-lg-11 p-3">
                      <button
                        type="button"
                        className="btn sign-up-button-color text-white px-5"
                        data-bs-toggle="modal"
                        data-bs-target="#staticBackdrop"
                        id="addTaskButton"
                      >
                        Add Task
                      </button>
                      {/* Modal */}
                      <div
                        className="modal fade"
                        id="staticBackdrop"
                        data-bs-backdrop="static"
                        data-bs-keyboard="false"
                        tabIndex={-1}
                        aria-labelledby="staticBackdropLabel"
                        aria-hidden="true"
                        style={{ zIndex: "9999" }}
                      >
                        <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable bg-transparent">
                          <div className="modal-content">
                            <div className="modal-header border-0">
                              <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                onClick={() => formik.resetForm()}
                              />
                            </div>
                            <div className="modal-body px-5">
                              <h3 className="textDarkBlack mb-4">Add Task</h3>
                              <form>
                                {/* <div className="mb-4">
                                  <div className="form-group">
                                    <select
                                      className="form-select form-control"
                                      aria-label="Default select example"
                                      id="definedepartment"
                                      name="department_objective_id"
                                      value={
                                        formik.values.department_objective_id
                                      }
                                      onChange={formik.handleChange}
                                    >
                                      <option>
                                        Select organizational objective
                                      </option>
                                      {subObjectiveList &&
                                        subObjectiveList.map((item) => {
                                          return (
                                            <option
                                              value={item?._id}
                                              key={item?._id}
                                            >
                                              {item?.dept_objective_name}
                                            </option>
                                          );
                                        })}
                                    </select>
                                  </div>
                                  {formik.errors.department_objective_id &&
                                  formik.touched.department_objective_id ? (
                                    <div className="text-danger">
                                      {formik.errors.department_objective_id}
                                    </div>
                                  ) : null}
                                </div> */}
                                <div className="mb-4">
                                  <span className="textSecondary">
                                    Enter Initiative towards the objective
                                  </span>
                                  <div className="form-group mt-1">
                                    <textarea
                                      className="form-control"
                                      placeholder="Make sure the task mentioned is pin pointed"
                                      id="floatingTextarea"
                                      rows="4"
                                      // maxLength="100"
                                      name="objective_initiative_task"
                                      value={
                                        formik.values.objective_initiative_task
                                      }
                                      onChange={formik.handleChange}
                                    ></textarea>
                                  </div>
                                  {formik.errors.objective_initiative_task &&
                                  formik.touched.objective_initiative_task ? (
                                    <div className="text-danger">
                                      {formik.errors.objective_initiative_task}
                                    </div>
                                  ) : null}
                                </div>
                                <div className="mb-4">
                                  <span className="textSecondary">
                                    Enter Key Result
                                  </span>
                                  <div className="form-group mt-1">
                                    <input
                                      type="text"
                                      name="key_result"
                                      value={formik.values.key_result}
                                      onChange={formik.handleChange}
                                      // maxLength="30"
                                      className="form-control mt-2"
                                      placeholder="Make sure to have pointed out comes of the following initiatve"
                                      id="exampleInputfirstname"
                                    />
                                  </div>
                                  {formik.errors.key_result &&
                                  formik.touched.key_result ? (
                                    <div className="text-danger">
                                      {formik.errors.key_result}
                                    </div>
                                  ) : null}
                                </div>
                                {/* <div className="mb-4">
                                  <span className="textSecondary">
                                    Mention the owners and dependencies
                                  </span>
                                  <div className="form-group mt-1">
                                    <Select
                                      className="multiselect-owner"
                                      name="owner_dependencies"
                                      onChange={(selectedOptions) => {
                                        const ownerIds = selectedOptions.map(
                                          (selected) => selected.value
                                        );
                                        formik.setFieldValue(
                                          "owner_dependencies",
                                          ownerIds
                                        );
                                      }}
                                      defaultValue={
                                        formik.values.owner_dependencies
                                      }
                                      options={options}
                                      isMulti
                                    
                                    />
                                  </div>
                                </div> */}
                                <button
                                  type="submit"
                                  onClick={formik.handleSubmit}
                                  className="btn font-size-14  sign-up-button-color text-white px-5"
                                >
                                  Publish
                                </button>
                              </form>
                            </div>
                            <div className="modal-footer border-0"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>

        {/* Modal for Owner Listing */}
        {/* <div className="row d-flex align-items-center justify-content-center mt-3">
         
          <div
            className="modal fade SelectOwner"
            id="staticBackdrop1"
            data-bs-backdrop="static"
            data-bs-keyboard="false"
            tabIndex={-1}
            aria-labelledby="staticBackdropLabel1"
            aria-hidden="true"
            style={{ zIndex: "9999" }}
          >
            <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable bg-transparent">
              <div className="modal-content">
                <div className="modal-header border-0">
                  <button
                    type="button"
                    className="btn-close"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  />
                </div>
                <div
                  className="modal-body px-5"
                  style={{ maxHeight: "400px", overflowY: "auto" }}
                >
                  <h3 className="textDarkBlack mb-4">Add Owners</h3>
                  <form>
                    <div className="mb-4">
                      <span className="textSecondary">
                        Mention the dependencies
                      </span>
                      <div className="form-group mt-1">
                        <Select
                          placeholder="Enter name or select the names below"
                          className="multiselect-owner"
                          name="owner_dependencies"
                          onChange={(selectedOptions) => {
                            const ownerIds = selectedOptions.map(
                              (selected) => selected.value
                            );
                            formik.setFieldValue(
                              "owner_dependencies",
                              ownerIds
                            );
                          }}
                          defaultValue={formik.values.owner_dependencies}
                          options={options}
                          isMulti
                        />
                      </div>
                      {formik.errors.owner_dependencies &&
                      formik.touched.owner_dependencies ? (
                        <div className="text-danger">
                          {formik.errors.owner_dependencies}
                        </div>
                      ) : null}
                    </div>
                    <button
                      type="submit"
                      onClick={() => updateOwner()}
                      className="btn font-size-14  sign-up-button-color text-white px-5 float-end"
                    >
                      Publish
                    </button>
                  </form>
                </div>
                <div className="modal-footer border-0"></div>
              </div>
            </div>
          </div>
        </div> */}

        {/* Modal end for owner listing */}
      </div>
    </AppLayout>
  );
}
